import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#000' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 16 16">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333M10.667 1.333V4M5.333 1.333V4M3 6.667h10.5"
      ></path>
    </svg>
  );
}

export default Icon;
