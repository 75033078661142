import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        stroke="#1E44F0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      ></path>
      <path stroke="#1E44F0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10.767l4 4 4-4"></path>
    </svg>
  );
}

export default Icon;
