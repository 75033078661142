import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateLabelDTO,
  DeleteLabelDTO,
  DeleteLabelOkDTO,
  GetLabelsResponse,
  Label,
  LabelablesDTO,
  LabelPickOkDTO,
  LabelState,
  PickLabelDTO,
  UnpickLabelDTO,
  UpdateLabelDTO,
} from './types';

export const initialState: LabelState = {
  loading: false,
  labelsById: {},
  labelables: {
    'App\\Models\\Invitation': [],
    'App\\Models\\Person': [],
    'App\\Models\\Submission': [],
  },
};

const labelSlice = createSlice({
  name: 'LABEL',
  initialState,
  reducers: {
    reset: () => initialState,
    ['LABEL:GET']: (state) => ({
      ...state,
      loading: true,
    }),
    ['LABEL:GET:OK']: (state, action: PayloadAction<GetLabelsResponse>) => ({
      ...state,
      loading: false,
      labelsById: action.payload.labelsById,
    }),
    ['LABELABLES:GET:OK']: (state, action: PayloadAction<LabelablesDTO>) => {
      const modelClass = action.payload.model;
      return {
        ...state,
        labelables: {
          ...state.labelables,
          [modelClass]: { ...state.labelables[modelClass], ...action.payload.labelablesById },
        },
      };
    },
    ['LABEL:PICK']: (state, _action: PayloadAction<PickLabelDTO>) => ({
      ...state,
    }),
    ['LABEL:PICK:OK']: (state, action: PayloadAction<LabelPickOkDTO>) => {
      const { labelIdsByRecordId, model } = action.payload;
      const modelClass = model;
      return {
        ...state,
        labelables: {
          ...state.labelables,
          [modelClass]: {
            ...state.labelables[modelClass],
            ...labelIdsByRecordId,
          },
        },
      };
    },
    ['LABEL:UNPICK']: (state, _action: PayloadAction<UnpickLabelDTO>) => ({
      ...state,
    }),
    ['LABEL:CREATE']: (state, _action: PayloadAction<CreateLabelDTO>) => ({
      ...state,
    }),
    ['LABEL:CREATE:OK']: (state, action: PayloadAction<Label>) => {
      return {
        ...state,
        labelsById: {
          ...state.labelsById,
          [action.payload.key]: action.payload,
        },
      };
    },
    ['LABEL:UPDATE']: (state, _action: PayloadAction<UpdateLabelDTO>) => ({
      ...state,
    }),
    ['LABEL:UPDATE:OK']: (state, action: PayloadAction<UpdateLabelDTO>) => {
      const { labelId, data } = action.payload;
      return {
        ...state,
        labelsById: {
          ...state.labelsById,
          [labelId]: {
            ...state.labelsById[labelId],
            label: data.name,
            background_color: data.backgroundColor,
            text_color: data.textColor,
          },
        },
      };
    },
    ['LABEL:DELETE']: (state, _action: PayloadAction<DeleteLabelDTO>) => ({
      ...state,
    }),
    ['LABEL:DELETE:OK']: (state, action: PayloadAction<DeleteLabelOkDTO>) => {
      const { labelId, model } = action.payload;

      const modelClass = model;
      const labelablesById: { [key: number]: number[] } = {};

      Object.entries(state.labelables[modelClass]).forEach(([key, value]) => {
        labelablesById[parseInt(key)] = value.filter((e) => e != labelId);
      });

      // Remove label
      const newLabelsById = {};
      for (const [key, value] of Object.entries(state.labelsById)) {
        if (key !== labelId.toString()) {
          // @ts-ignore
          newLabelsById[key] = value;
        }
      }
      return {
        ...state,
        labelsById: newLabelsById,
        labelables: {
          ...state.labelables,
          [modelClass]: labelablesById,
        },
      };
    },
  },
});

export default labelSlice;
