import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#2D53F1"
        d="M21.71 2.295a.995.995 0 01.29.702V9a1 1 0 11-2 0V5.414l-5.293 5.293a1 1 0 01-1.414-1.414L18.586 4H15a1 1 0 110-2h6c.275 0 .524.111.705.29l.004.005zM10.707 14.707L5.414 20H9a1 1 0 110 2H2.997a.997.997 0 01-.702-.29l-.004-.005A.997.997 0 012 21v-6a1 1 0 112 0v3.586l5.293-5.293a1 1 0 011.414 1.414z"
      ></path>
    </svg>
  );
}

export default Icon;
