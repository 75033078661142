import React, { useEffect } from 'react';
import { Format } from '../../store/form/types';
import renderMathInElement from 'katex/dist/contrib/auto-render';

interface Props {
  value: string;
  format: Format;
}

const TextAreaDisplay: React.FC<Props> = ({ value, format }) => {
  const ref = React.useRef(null);

  // Idea taken from https://github.com/KaTeX/KaTeX/issues/1649#issuecomment-656776330
  useEffect(() => {
    if (ref.current && format == 'latex') {
      renderMathInElement(ref.current, {
        delimiters: [
          { left: '$$', right: '$$', display: true },
          { left: '$', right: '$', display: false },
          { left: '\\(', right: '\\)', display: false },
          { left: '\\[', right: '\\]', display: true },
        ],
      });
    }
  }, [value]);

  return (
    <span className="whitespace-pre-line" ref={ref}>
      {value}
    </span>
  );
};

export default TextAreaDisplay;
