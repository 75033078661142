import { AppState } from './state';
import { AuthState } from './auth/types';
import { User, UserAlias, UserState } from './user/types';
import { TableState } from './table/types';
import { Conference, ConferenceState, Role, RoleRelationType, Track } from './conference/types';
import { PermissionState, PermissionString } from './permission/types';
import { SubmissionState } from './submission/types';
import { FormState } from './form/types';
import { ReviewState } from './review/types';
import { InfoState } from './info/types';
import { LabelState } from './label/types';
import { EmailState } from './email/types';
import { BidState } from './bid/types';
import { PaperStatusState } from './paper-status/types';
import { ErrorState } from './error/types';

export const selectAuth = (state: AppState): AuthState => state.auth;
export const selectUser = (state: AppState): UserState => state.user;
export const selectTable = (state: AppState): TableState => state.table;
export const selectConference = (state: AppState): ConferenceState => state.conference;
export const selectPermission = (state: AppState): PermissionState => state.permission;
export const selectSubmissionState = (state: AppState): SubmissionState => state.submission;
export const selectKeywordState = (state: AppState): KeywordState => state.keyword;
export const selectFormState = (state: AppState): FormState => state.form;
export const selectReviewState = (state: AppState): ReviewState => state.review;
export const selectInfo = (state: AppState): InfoState => state.info;
export const selectScreenState = (state: AppState): ScreenState => state.screen;
export const selectLabelState = (state: AppState): LabelState => state.label;
export const selectEmailState = (state: AppState): EmailState => state.email;
export const selectBidState = (state: AppState): BidState => state.bid;
export const selectPaperStatusState = (state: AppState): PaperStatusState => state.paperStatus;
export const selectSideMenuState = (state: AppState): SideMenuState => state.sidemenu;
export const selectErrorState = (state: AppState): ErrorState => state.error;
export const selectPhaseState = (state: AppState): PhaseState => state.phase;

export const selectIsLoading = (state: AppState): boolean =>
  selectAuth(state).loading || selectConference(state).loading;

export const selectCurrentConference = (state: AppState): Conference | null => {
  const stateConference = selectConference(state);
  const track = selectCurrentTrack(state);
  return track ? stateConference.conferencesById[track.conference_id] : null;
};

export const selectCurrentTrack = (state: AppState): Track | null => {
  const stateConference = selectConference(state);
  let currentTrack = null;
  if (stateConference.currentTrackId !== null)
    currentTrack = stateConference.tracksById[stateConference.currentTrackId];
  return currentTrack;
};

export const selectCurrentRole = (state: AppState): Role | null => {
  const stateConference = selectConference(state);
  let currentRole = null;
  if (stateConference.currentRoleId !== null) currentRole = stateConference.roleById[stateConference.currentRoleId];
  return currentRole;
};

export const selectTrackRoles = (state: AppState): { [key: string]: Role } => {
  const roleById: { [key: string]: Role } = {};
  const track = selectCurrentTrack(state);
  if (track) {
    Object.values(selectConference(state).roleById)
      .filter((role) => role.track_id == track.id)
      .forEach((role) => (roleById[role.id] = role));
  }
  return roleById;
};

export const selectCurrentUser = (state: AppState): User => {
  return selectUser(state).data;
};

export const selectCurrentAlias = (state: AppState): UserAlias => {
  const user = selectCurrentUser(state);
  return user.user_alias_by_id[user.id];
};

export const selectImpersonatedTrackId = (state: AppState): number | null => {
  return selectAuth(state).impersonatedTrackId;
};

export const selectImpersonatedOriginLocation = (state: AppState): string => {
  return selectAuth(state).impersonatedOriginLocation;
};

export const selectIsEmailVerified = (state: AppState): boolean => {
  if (!Object.keys(selectCurrentUser(state).user_alias_by_id).length) {
    return false;
  }
  return selectCurrentAlias(state).email_verified_at !== null;
};

export const selectUserPermissions = (state: AppState): PermissionString[] => {
  const role = selectCurrentRole(state);
  return role ? selectPermission(state).byRoleId[role.id] : [];
};

export const selectRoleRelations = (state: AppState, type: RoleRelationType): number[] => {
  const role = selectCurrentRole(state);
  const roleRelationsById = selectConference(state).roleRelationsById[type] ?? [];

  let roleIds;
  if (role?.type === 'chair') {
    // Chair get all possible role ids.
    roleIds = Object.keys(selectTrackRoles(state)).map((roleId) => parseInt(roleId));
  } else {
    // Get roles as set in Role Relations settings
    roleIds = roleRelationsById[role?.id ?? 0] ?? [];
  }
  return roleIds;
};
