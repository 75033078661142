import React from 'react';
import { Submission } from '../../store/submission/types';
import { Link } from '../ui';
import { Answer, Answerable, Form, Question } from '../../store/form/types';
import TabForms from '../TabForms/TabForms';
import { Review } from '../../store/review/types';
import { Role, RoleType } from '../../store/conference/types';
import useTheme from '@material-ui/core/styles/useTheme';
import styled, { ThemeProvider } from 'styled-components';
import EditIcon from '../../icons/Edit';
import history from '../../store/history';
import { fillRoutePath } from '../../helpers/path';
import { getRouteByName } from '../../router/routes';
import { Button } from '../ui';
import { AssignmentPermissionManager, can } from '../../helpers/permissions';
import StatusLabel from '../StatusLabel/StatusLabel';
import ChipMessage from '../ui/display/Chip/ChipMessage';
import { computeDefaultQuestionsNumber, formPendingQuestions } from '../../helpers/form';
import { useTranslation } from 'react-i18next';
import { getFormName } from '../../helpers/translations';
import DefaultSubmissionForm from '../DefaultSubmissionForm/DefaultSubmissionForm';
import Loading from '../Loading/Loading';

interface Props {
  isLoadingReviews: boolean;
  submission: Submission;
  keywordById: { [key: number]: Keyword };
  roleById: { [key: string]: Role };
  formsData: {
    answerable: Answerable;
    formsData: { form: Form; questions: Question[]; answers: Answer[] }[];
  }[];
  identityRoleIds: number[];
  currentRoleId: number;
  emailRoleIds: number[];
  personId: number;
  onEditClick?: () => void;
  onSendEmailClick?: () => void;
  onViewDiscussionClick?: () => void;
}

/**
 * Push a route to conference home or all submissions page depending on role.
 * @param currentRoleType
 */
export const goBackFromSubmissionDetail = (currentRoleType: RoleType): void => {
  const canBack = !!history.location.key; // From https://stackoverflow.com/questions/37385570/how-to-know-if-react-router-can-go-back-to-display-back-button-in-react-app

  if (canBack && history.length > 1) {
    history.goBack();
  } else if (currentRoleType == 'chair') {
    history.push(fillRoutePath(getRouteByName('RouteAllSubmissions').path));
  } else {
    history.push(fillRoutePath(getRouteByName('RouteConferenceHome').path));
  }
};

const SubmissionDetail: React.FC<Props> = ({
  isLoadingReviews,
  submission,
  keywordById,
  roleById,
  formsData,
  identityRoleIds,
  currentRoleId,
  emailRoleIds,
  personId,
  onEditClick,
  onSendEmailClick,
  onViewDiscussionClick,
}) => {
  const { t, i18n } = useTranslation();
  // useState hooks
  // Selected item state used for both, submenu items and external controlled state for tab forms
  const [selectedItemChange, setSelectedItemChange] = React.useState<string | undefined>();

  const currentRole = roleById[currentRoleId];
  const currentRoleType = currentRole.type;

  const canViewAuthors = !!identityRoleIds.map((roleId) => roleById[roleId]).find((role) => role.type === 'author');

  const actionsMenuVisibility = !!(
    currentRoleType == 'chair' ||
    (emailRoleIds.length > 0 && onSendEmailClick) ||
    onEditClick ||
    onViewDiscussionClick
  );

  const submissionFormsData = formsData.filter(({ formsData }) =>
    formsData.some(({ form }) => form.type == 'Submission'),
  );

  const reviewFormsData = formsData.filter(({ formsData }) => formsData.some(({ form }) => form.type == 'Review'));

  // Default form questions content
  const defaultSubmissionFormData = submissionFormsData[0].formsData.find(({ form }) => form.is_default);

  const theme = useTheme();

  // Function to scroll to a title section
  const scrollToTitle = (title: string) => {
    const targetElement = document.querySelector(`[id="${title}"]`);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /*
    Function to control what submenu or tab element is clicked and scroll to it.
    Submenu items has main: prefix except submission forms list
    To control if clicked in submission form or not through prefix 'main'
    and change behavior
  */
  const onSelectedItemChange = (tabName: string) => {
    setSelectedItemChange(tabName);

    if (tabName.split(':').length > 1 && tabName.split(':')[0] === 'main') {
      scrollToTitle(tabName.split(':')[1]);
    } else {
      scrollToTitle('paper-information');
    }
  };

  // Render HTML Elements

  // JSX elements
  const submissionFormListElements: JSX.Element[] = [];
  const tabFormElements: JSX.Element[] = [];
  const reviewsListElements: JSX.Element[] = [];

  // Submission forms list for "In this page" submenu
  if (submissionFormsData.length > 0) {
    const { answerable, formsData } = submissionFormsData[0];
    formsData.forEach(({ form, questions, answers }) => {
      const numPending = formPendingQuestions(form, questions, answers, answerable);

      submissionFormListElements.push(
        <li key={form.id}>
          <Link
            className={`flex items-center ${selectedItemChange === form.name ? 'font-normal' : ''}`}
            onClick={() => {
              onSelectedItemChange(form.name);
            }}
          >
            <div
              className={`${
                !!onEditClick && numPending > 0 ? 'bg-red-600' : 'bg-blue-600'
              } text-lg ml-1.5 mr-3.5 pt-1 w-1.5 h-1.5 rounded-full`}
            ></div>
            <span className={`${!!onEditClick && numPending > 0 ? 'text-red-600' : ''}`}>
              {getFormName(form.name, form.is_default, t)}
            </span>
          </Link>
        </li>,
      );
    });
  }

  // Review tab forms content and reviews list for "In this page" submenu
  reviewFormsData.forEach((data) => {
    const { answerable, formsData } = data;
    const review = answerable as Review;
    const isIncompletedReview = review.validation_status != 'Complete';
    const pm = new AssignmentPermissionManager(review);
    const isOwner = pm.isAuthor(personId, currentRoleId);
    const canEdit = pm.canEdit(
      personId,
      roleById[currentRoleId],
      formsData.map(({ form }) => form),
    );

    // Review tab forms content
    tabFormElements.push(
      <div key={review.id} id={`review${review.external_id}`}>
        <StyledReviewTitle className="flex">
          <div className="flex">
            <span className="font-bold mr-3">
              {t('Reviewer')} #{review.external_id}
            </span>
            <span className="mr-2">
              {review.person.full_name} ({roleById && roleById[review.role_id].description})
              {isOwner ? ' ' + t('(yours)') : ''}
            </span>
            {canEdit && (
              <Link
                onClick={() => {
                  history.push(
                    fillRoutePath(getRouteByName('RouteEditReview').path, {
                      submissionId: submission.external_id.toString(),
                      reviewId: review.external_id.toString(),
                    }),
                  );
                }}
                className="inline-flex text-sm ml-3 cursor-pointer items-center"
              >
                <EditIcon color={theme.palette.primary.main} className="mr-1" />
                {t('Edit Review')}
              </Link>
            )}
          </div>

          {canEdit && isIncompletedReview && (
            <ChipMessage message={t('Required questions pending')} colorType="warning" />
          )}
        </StyledReviewTitle>

        <TabForms data={data} mode="view" canViewFeedback={canEdit} />
      </div>,
    );

    // Reviews list for "In this page" submenu
    reviewsListElements.push(
      <li key={review.id}>
        <Link
          className={`flex items-center ${
            selectedItemChange?.split(':')[1] === `review${review.external_id}` ? 'font-normal' : ''
          }`}
          onClick={() => {
            onSelectedItemChange(`main:review${review.external_id}`);
          }}
        >
          <div
            className={`${
              canEdit && isIncompletedReview ? 'bg-red-600' : 'bg-blue-600'
            } text-lg ml-1.5 mr-3.5 pt-1 w-1.5 h-1.5 rounded-full`}
          ></div>
          <span className={`${canEdit && isIncompletedReview ? 'text-red-600' : ''}`}>
            {t('Review')} #{review.external_id} {review.person.full_name ? review.person.full_name : ''} (
            {roleById && roleById[review.role_id].description}) {isOwner ? t('(yours)') : ''}
          </span>
        </Link>
      </li>,
    );
  });

  // Render page
  return (
    <ThemeProvider theme={theme}>
      <StyledSubmissionDetailWrapper style={{ flexGrow: 1 }}>
        <div className="flex justify-start h-full">
          {/* PAPER MAIN CONTENT */}
          <StyledContentWrapper>
            {/* HEADER */}
            <header className="text-sm font-semibold flex items-center justify-between flex-row mb-6">
              <StyledSubmissionId>
                <p>#{submission.external_id}</p>
              </StyledSubmissionId>

              <StyledSubmissionTitle>
                {submission.title || (onEditClick && can('CAN_EDIT_TITLE') && !submission.title) ? (
                  <h2>{submission.title ? submission.title : t('Untitled paper')}</h2>
                ) : (
                  <div style={{ flexGrow: 1 }}></div>
                )}

                {onEditClick && submission.validation_status === 'Pending' && (
                  <StatusLabel label={t('Submission')} validationStatus={submission.validation_status} />
                )}
              </StyledSubmissionTitle>
            </header>

            {/* TABFORMS */}
            {submissionFormsData.length > 0 && (
              <div id="paper-information">
                <StyledTabFormsWrapper className="mt-5">
                  <TabForms
                    data={submissionFormsData[0]}
                    mode="view"
                    canViewFeedback={!!onEditClick}
                    onFormTabChange={onSelectedItemChange}
                    formTabValue={
                      selectedItemChange && selectedItemChange.split(':')[0] !== 'main'
                        ? selectedItemChange
                        : submissionFormsData[0].formsData[0].form.name
                    }
                    defaultSubmissionQuestions={
                      defaultSubmissionFormData &&
                      defaultSubmissionFormData.form.default_questions && (
                        <DefaultSubmissionForm
                          submission={submission}
                          default_questions={defaultSubmissionFormData.form.default_questions}
                          canViewAuthors={canViewAuthors}
                          keywordById={keywordById}
                          canViewFeedback={!!onEditClick}
                        />
                      )
                    }
                    numDefaultQuestions={computeDefaultQuestionsNumber(keywordById, canViewAuthors)}
                  />
                </StyledTabFormsWrapper>
              </div>
            )}
            {isLoadingReviews ? (
              <Loading text="Loading reviews" compact={true} />
            ) : (
              reviewFormsData.length > 0 && (
                <StyledReviewsSection className="mt-5" id="reviews">
                  <h2>{t('Reviews')}</h2>

                  {tabFormElements}
                </StyledReviewsSection>
              )
            )}
          </StyledContentWrapper>

          {/* LATERAL SUBMENU */}
          <StyledSubmenu>
            <>
              {/* ACTIONS SUBMENU */}
              <div>
                {actionsMenuVisibility && (
                  <>
                    <h2>{t('Actions')}</h2>
                    <ul>
                      {onEditClick && (
                        <li>
                          <Link onClick={() => onEditClick()}>{t('Edit Submission')}</Link>
                        </li>
                      )}
                      {emailRoleIds.length > 0 && onSendEmailClick && (
                        <li>
                          <Link onClick={() => onSendEmailClick()}>{t('Send Email')}</Link>
                        </li>
                      )}
                      {onViewDiscussionClick && (
                        <li>
                          <Link onClick={() => onViewDiscussionClick()}>{t('Join discussion')}</Link>
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </div>

              {/* IN THIS PAGE SUBMENU */}
              <h2 className={actionsMenuVisibility ? 'mt-10' : ''}>{t('In this page')}</h2>

              <ul className="in-this-page pr-2">
                {/* Submission Forms List in submenu */}
                {submissionFormsData.length > 0 && submissionFormsData[0].formsData.length > 0 && (
                  <li>
                    <Link
                      onClick={() => {
                        onSelectedItemChange('main:paper-information');
                      }}
                      className={`${
                        (selectedItemChange && !selectedItemChange.includes('main:')) ||
                        selectedItemChange === 'main:paper-information'
                          ? 'font-normal'
                          : ''
                      }`}
                    >
                      {t('Submission forms')}
                    </Link>
                    <ul>{submissionFormListElements}</ul>
                  </li>
                )}

                {/* Reviews List in submenu */}
                {isLoadingReviews ? (
                  <Loading text="Loading reviews" compact={true} />
                ) : (
                  Object.entries(reviewFormsData).length > 0 && (
                    <li>
                      <Link
                        onClick={() => {
                          onSelectedItemChange('main:reviews');
                        }}
                        className={`${
                          selectedItemChange && selectedItemChange.includes('main:review') ? 'font-normal' : ''
                        }`}
                      >
                        {t('Reviews')}
                      </Link>
                      <ul>{reviewsListElements}</ul>
                    </li>
                  )
                )}
              </ul>
            </>
          </StyledSubmenu>
        </div>

        {/* FOOTER BUTTONS */}
        <StyledButtonsWrapper>
          <Button variant="text" className="cursor-pointer" onClick={() => goBackFromSubmissionDetail(currentRoleType)}>
            <span className=" font-bold text-red-600 ">{t('Close')}</span>
          </Button>
        </StyledButtonsWrapper>
      </StyledSubmissionDetailWrapper>
    </ThemeProvider>
  );
};

export default SubmissionDetail;

const StyledSubmissionDetailWrapper = styled.div`
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  max-width: 72rem;
`;

const StyledSubmenu = styled.section`
  position: sticky;
  top: 0;
  align-self: flex-start;
  padding-right: 2rem;
  padding-left: 5rem;

  @media (max-width: 1280px) {
    padding-left: 3rem;
    padding-right: 0;
  }

  ul {
    color: ${(props) => props.theme.palette.text.hint};

    li {
      margin-bottom: 0.5rem;

      a {
        /*color: ${(props) => props.theme.palette.text.hint}!important;*/
        cursor: pointer;
      }
    }
  }

  > ul {
    border-left: 2px solid ${(props) => props.theme.palette.text.hint};
    padding-left: 1rem;

    ul {
      margin-top: 0.5rem;
      padding-left: 1rem;
    }
  }

  .in-this-page {
    overflow-y: auto;
    max-height: 52vh;
  }
`;

const StyledSubmissionId = styled.div`
  border-right: 2px solid ${(props) => props.theme.palette.secondary.light};
  background-color: ${(props) => props.theme.palette.secondary.light};
  padding: 0.5rem;
  border-radius: 5px;

  p {
    font-size: 1rem;
  }
`;

const StyledSubmissionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-right: 0.5rem;
  padding-left: 1rem;
  background-color: ${(props) => props.theme.palette.background.paper};

  h2 {
    flex-grow: 1;
    font-size: 1rem;
    margin-bottom: 0;
  }

  p {
    padding-left: 1.2rem;
  }

  > div {
    display: flex;
    align-items: center;
    padding-left: 2rem;

    span {
      padding-right: 0.4rem;
    }

    > div {
      width: auto;
      margin: 0;
      font-weight: normal;
    }
  }
`;

const StyledReviewsSection = styled.div`
  h2 {
    margin-bottom: 1.2rem;
  }
`;

const StyledReviewTitle = styled.div`
  margin-bottom: 0.75rem;
  justify-content: space-between;

  span {
    font-size: 0.875rem;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  background-color: #fff;
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
  z-index: 2;
`;

const StyledTabFormsWrapper = styled.div`
  .fuploaderWrapper {
    .text-black {
      >div: first-child span {
        color: rgb(0, 68, 240);
      }
    }
  }
`;
