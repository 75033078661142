import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#2D53F1"
        d="M12.747 2.49a.996.996 0 00-.38-.076h-.006a.997.997 0 00-.704.293L7.414 6.95a1 1 0 001.414 1.414l2.536-2.536v7.486a1 1 0 102 0V5.828l2.535 2.536a1 1 0 101.415-1.414L13.07 2.707l-.002-.002a.996.996 0 00-.322-.215z"
      ></path>
      <path
        fill="#2D53F1"
        d="M4 15a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 10-2 0v4a1 1 0 01-1 1H5a1 1 0 01-1-1v-4z"
      ></path>
    </svg>
  );
}

export default Icon;
