import React, { useState } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import SquaredAddButton from '../ui/inputs/SquaredAddButton/SquaredAddButton';
import { Form, FormVisibilityOption, updateVisibilityAction } from '../../store/form/types';
import { FormTypeEnum, FormVisibilityOptions } from '../../store/form/types.d';
import { formDefinition } from '../FormPermissions/FormPermissions';
import { difference } from '../../helpers/set';
import styled from 'styled-components';
import { Role } from '../../store/conference/types';
import { addAction, removeAction } from '../../helpers/phase';
import FormPermissionCard from '../FormPermissionCard/FormPermissionCard';
import { useTranslation } from 'react-i18next';
import { getFormName, getRoleName, getVisibilityOptionName } from '../../helpers/translations';

const updateSinglePermission = (index: any, data: updateVisibilityAction, actions: PhaseAction[]): PhaseAction[] => {
  const { target_role_id, visibility, to_role_id } = data;

  const findAction = actions[index];

  const findActionPermissions: {
    [key: string]: { to_role_id: string | null; visibility: FormVisibilityOption }[];
  } = { ...findAction.body.params.permissions };

  const permissionToChange: {
    [key: string]: { to_role_id: string | null; visibility: FormVisibilityOption }[];
  } = {
    [target_role_id]: [
      {
        to_role_id: to_role_id ? to_role_id.toString() : null,
        visibility: visibility,
      },
    ],
  };

  const updatedPermissions: {
    [key: string]: {
      to_role_id: string | null;
      visibility: FormVisibilityOption;
    }[];
  } = {};

  Object.keys(findActionPermissions).forEach((key) => {
    updatedPermissions[key] = findActionPermissions[key].map((permission) => {
      const updatedPermission =
        permissionToChange[key] && permissionToChange[key].find((data) => data.to_role_id == permission.to_role_id);

      return updatedPermission ? updatedPermission : permission;
    });
  });

  return actions.map((action, idx) =>
    idx === index
      ? {
          ...action,
          body: {
            ...action.body,
            params: { ...action.body.params, permissions: updatedPermissions },
          },
        }
      : action,
  );
};

interface AttachForm {
  form: {
    id?: string;
    text: string;
  };
  role: {
    id?: string;
    text: string;
  };
  permission: {
    id?: string;
    text: string;
  };
  roleDest: {
    id?: string | null;
    text: string;
  };
  property: {
    id?: string;
    text: string;
  };
}

interface Props {
  roleById: { [key: string]: Role };
  formById: { [key: number]: Form };
  phaseType: PhaseType;
  eventType: PhaseEventType;
  actions: PhaseAction[];
  onChange: (actions: PhaseAction[]) => void;
}

const FormPermissionPicker: React.FC<Props> = ({ formById, roleById, phaseType, eventType, actions, onChange }) => {
  const { t, i18n } = useTranslation();
  const initAttachForm = (): AttachForm => {
    return {
      form: defaultAttachFormValue,
      role: defaultAttachRoleValue,
      permission: defaultAttachPermissionValue,
      property: defaultAttachPropertyValue,
      roleDest: defaultAttachRoleDestValue,
    };
  };

  /* Form picker input */
  const defaultAttachFormValue = { text: t('Attach form') };
  const defaultAttachRoleValue = { text: t('Choose role') };
  const defaultAttachPermissionValue = { text: t('Choose permission') };
  const defaultAttachPropertyValue = { text: t('Choose property') };
  const defaultAttachRoleDestValue = { text: t('Choose role destination') };
  const [attachForm, setAttachForm] = useState<AttachForm>(initAttachForm);

  /*
    Function that return an array of strings like "2-null" or "3-3"
    from a parameter permissions: [key: string]: { to_role_id: string | null; visibility: string;}[]
  */
  const concatKeysFromPermissions = (permissions: {
    [key: string]: { to_role_id: string | null; visibility: string }[];
  }) => {
    const concatKeys: string[] = [];
    Object.entries(permissions).forEach(([roleId, permissionsData]) => {
      permissionsData.forEach((data) => {
        concatKeys.push(toKey(parseInt(roleId), data.to_role_id));
      });
    });
    return concatKeys;
  };

  const toKey = (fromRoleId: number, toRoleId: string | null): string => {
    return `${fromRoleId}-${toRoleId}`;
  };

  /*
    Function that receives string like '2-null' or '2-3' and return object like {role_id: 2, to_role_id: 3}
    to access the properties object.role_id and object.to_role_id
  */
  const fromKey = (permissionKey: string): { role_id: number; to_role_id: string | null } => {
    const [roleId, toRoleId] = permissionKey.split('-');

    return { role_id: parseInt(roleId), to_role_id: toRoleId };
  };

  /*
      Function returns a Set of available options.
      I'll call this function in computeRoleOptions, computeDestRoleOptions and computePropertyOptions
      to show the correct options every time
    */
  const computeAvailableOptions = () => {
    const allKeys = new Set();

    const attachFormIdInt = attachForm.form.id !== undefined ? parseInt(attachForm.form.id) : null;

    if (attachFormIdInt !== null) {
      Object.values(formById)
        .filter((form) => form.id === attachFormIdInt)
        .map((form) => {
          const roleIds: number[] = [];

          Object.values(roleById)
            .filter((role) => role.type !== 'chair')
            .map((role) => {
              {
                roleIds.push(role.id);
              }
            });

          roleIds.forEach((sourceRole) => {
            if (
              !(formById[form.id].type === 'Submission' && roleById[sourceRole].type === 'reviewer') &&
              !(formById[form.id].type === 'Review' && roleById[sourceRole].type === 'author')
            ) {
              allKeys.add(toKey(sourceRole, null));
            }
          });

          roleIds.forEach((sourceRole) => {
            roleIds.forEach((destRole) => {
              const notValid =
                (roleById[sourceRole].type === 'author' && roleById[destRole].type === 'author') ||
                (formById[form.id].type === 'Submission' &&
                  roleById[sourceRole].type === 'reviewer' &&
                  roleById[destRole].type === 'reviewer') ||
                (formById[form.id].type === 'Submission' &&
                  roleById[sourceRole].type === 'author' &&
                  roleById[destRole].type === 'reviewer') ||
                (formById[form.id].type === 'Review' &&
                  roleById[sourceRole].type === 'reviewer' &&
                  roleById[destRole].type === 'author');

              if (!notValid) {
                allKeys.add(toKey(sourceRole, destRole.toString()));
              }
            });
          });
        });
    }

    let currentSelected = new Set();

    actions
      .filter((action) => action.type == 'form_permission_change')
      .map((action) => action.body.params)
      .filter((form) => form.form_id === attachFormIdInt)
      .forEach((form) => {
        currentSelected = new Set(concatKeysFromPermissions(form.permissions));
      });

    const diff = difference(allKeys, currentSelected);

    return diff;
  };

  /* Compute which actions to display in the dropdown */
  const computeFormOptions = () => {
    let forms = Object.values(formById);
    if (phaseType == 'submission') {
      forms = forms.filter((form) => form.type == FormTypeEnum.Submission);
    }
    return forms.map((form) => {
      return { text: getFormName(form.name, form.is_default, t), id: form.id };
    });
  };

  const computeRoleOptions = () => {
    const availableOptions: number[] = [];

    const availableOptionsSet = computeAvailableOptions();

    availableOptionsSet.forEach((option) => {
      availableOptions.push(fromKey(option).role_id);
    });

    return Object.values(roleById)
      .filter((role) => role.type !== 'chair')
      .filter((role) => availableOptions.includes(role.id))
      .map((role) => ({ id: role.id.toString(), text: getRoleName(role, t) }));
  };

  const computeDestRoleOptions = () => {
    const availableOptionsSet = computeAvailableOptions();

    const roleId = attachForm.role.id ? parseInt(attachForm.role.id) : null;

    const availableOptions = Array.from(availableOptionsSet)
      .filter((option) => {
        return (!roleId || fromKey(option).role_id === roleId) && fromKey(option).to_role_id !== 'null';
      })
      .map((option) => fromKey(option).to_role_id);

    return Object.values(roleById)
      .filter((role) => role.type !== 'chair' && role.type !== 'author')
      .filter((role) => availableOptions.includes(role.id.toString()))
      .map((role) => {
        const text = `${roleId == role.id ? t('other') : ''} ${getRoleName(role, t)}`;
        return { id: role.id.toString(), text };
      });
  };

  const computeVisibilityOptions = (formId: number, roleId: number): FormVisibilityOption[] => {
    const form = formById[formId];
    const role = roleById[roleId];
    const definition = formDefinition.find((value) => value.formType == form.type);
    if (!definition) throw Error('Form definition not found.');

    let options = FormVisibilityOptions;

    // Remove write option if the role does not have write permissions
    if (!definition.editRoles.includes(role.type)) {
      options = options.filter((option) => option != 'write');
    }

    if (role.type == 'author' && form.is_default) {
      options = options.filter((option) => option != 'hidden');
    }

    return options;
  };

  const computePropertyOptions = (formId: number, roleId: number): Array<{ id: string | null; text: string }> => {
    const form = formById[formId];
    const role = roleById[roleId];

    const availableOptionsSet = computeAvailableOptions();

    const selectedRoleId = attachForm.role.id ? parseInt(attachForm.role.id) : null;

    const availableOptions = Array.from(availableOptionsSet)
      .filter((option) => {
        return !selectedRoleId || fromKey(option).role_id === selectedRoleId;
      })
      .map((option) => fromKey(option).to_role_id);

    const optionsArray: { id: string | null; text: string }[] = [];

    if (availableOptions.includes('null')) {
      optionsArray.push({
        id: null,
        text: t('own answers'),
      });
    }
    if (availableOptions.length > 0) {
      optionsArray.push({
        id: '1',
        text: t('answers from'),
      });
    }

    return form && role && form.type === 'Review' && role.type !== 'author' ? optionsArray : [];
  };

  const isValid = (): boolean => {
    const condForSubmissionForm =
      attachForm.form.id != undefined &&
      formById[parseInt(attachForm.form.id)].type === 'Submission' &&
      !!attachForm.role.id &&
      !!attachForm.permission.id;

    const condForAuthorAndReviewForm =
      attachForm.form.id != undefined &&
      formById[parseInt(attachForm.form.id)].type === 'Review' &&
      !!attachForm.role.id &&
      roleById[attachForm.role.id].type === 'author' &&
      !!attachForm.permission.id &&
      !!attachForm.roleDest.id;

    const condForOwnAndReviewForm =
      attachForm.form.id != undefined &&
      formById[parseInt(attachForm.form.id)].type === 'Review' &&
      !!attachForm.role.id &&
      !!attachForm.permission.id &&
      attachForm.property.id === null;

    const condForOthersAndReviewForm =
      attachForm.form.id != undefined &&
      formById[parseInt(attachForm.form.id)].type === 'Review' &&
      !!attachForm.role.id &&
      !!attachForm.permission.id &&
      !!attachForm.property.id !== null &&
      !!attachForm.roleDest.id;

    return condForSubmissionForm || condForAuthorAndReviewForm || condForOwnAndReviewForm || condForOthersAndReviewForm;
  };

  const getFormPermissionArgs = (): FormPermissionActionArgs => {
    if (attachForm.form.id == undefined || !attachForm.role.id) {
      throw new Error('Form id is not selected.');
    }
    const role = roleById[attachForm.role.id];
    const authorRoleId = Object.values(roleById).filter((role) => role.type == 'author')[0].id;
    const formType = formById[parseInt(attachForm.form.id)].type;

    return {
      form_id: parseInt(attachForm.form.id),
      role_id: parseInt(attachForm.role.id),
      visibility: attachForm.permission.id,
      to_role_id: attachForm.roleDest.id
        ? parseInt(attachForm.roleDest.id)
        : role.type === 'reviewer' && formType === 'Submission'
        ? authorRoleId
        : null,
    };
  };

  const formContent: JSX.Element[] = [];
  actions.forEach((action, index) => {
    if (action.type == 'form_permission_change') {
      formContent.push(
        <FormPermissionCard
          key={index}
          action={action}
          phaseType={phaseType}
          eventType={eventType}
          roleById={roleById}
          formById={formById}
          onDelete={(roleId, toRoleId) =>
            onChange(
              removeAction(
                index,
                {
                  roleId: roleId.toString(),
                  toRoleId: toRoleId != null ? toRoleId.toString() : toRoleId,
                },
                actions,
              ),
            )
          }
          onPermissionClick={(data) => onChange(updateSinglePermission(index, data, actions))}
        />,
      );
    }
  });

  return (
    <div className="text-sm pt-0.5 flex flex-col form-element" style={{ maxWidth: '30rem' }}>
      {/* Show current form actions */}
      {formContent}
      {/* Form permission picker */}
      <div className="flex flex-col items-end">
        <div className="flex flex-col w-full" style={{ minWidth: '22.7rem' }}>
          <DropDownList
            style={{ width: '100%', height: '35.2px' }}
            value={attachForm.form}
            data={computeFormOptions()}
            textField="text"
            onChange={(e: DropDownListChangeEvent) =>
              setAttachForm({
                ...attachForm,
                form: e.target.value,
                role: defaultAttachRoleValue,
                permission: defaultAttachPermissionValue,
              })
            }
            className="mb-3"
          />
          {attachForm.form.id != undefined && computeRoleOptions().length > 0 && (
            <DropDownList
              style={{ width: '100%', height: '35.2px' }}
              value={attachForm.role}
              data={computeRoleOptions()}
              textField="text"
              onChange={(e: DropDownListChangeEvent) =>
                setAttachForm({ ...attachForm, role: e.target.value, permission: defaultAttachPermissionValue })
              }
              className="mb-3"
            />
          )}
          {attachForm.form.id != undefined && attachForm.role.id && (
            <DropDownList
              style={{ width: '100%', height: '35.2px' }}
              value={attachForm.permission}
              data={computeVisibilityOptions(parseInt(attachForm.form.id), parseInt(attachForm.role.id)).map(
                (visibility) => ({
                  id: visibility,
                  text: getVisibilityOptionName(visibility, t),
                }),
              )}
              textField="text"
              onChange={(e: DropDownListChangeEvent) => setAttachForm({ ...attachForm, permission: e.target.value })}
              className="mb-3"
            />
          )}
          {attachForm.form.id != undefined &&
            attachForm.permission.id &&
            attachForm.role.id &&
            computePropertyOptions(parseInt(attachForm.form.id), parseInt(attachForm.role.id)).length > 0 && (
              <DropDownList
                style={{ width: '100%', height: '35.2px' }}
                value={attachForm.property}
                data={computePropertyOptions(parseInt(attachForm.form.id), parseInt(attachForm.role.id))}
                textField="text"
                onChange={(e: DropDownListChangeEvent) => setAttachForm({ ...attachForm, property: e.target.value })}
                className="mb-3"
              />
            )}
          {attachForm.form.id != undefined &&
            computeDestRoleOptions().length > 0 &&
            attachForm.permission.id &&
            formById[parseInt(attachForm.form.id)].type === 'Review' &&
            attachForm.role.id &&
            (attachForm.property.id || roleById[attachForm.role.id].type === 'author') && (
              <DropDownList
                style={{ width: '100%', height: '35.2px' }}
                value={attachForm.roleDest}
                data={computeDestRoleOptions()}
                textField="text"
                onChange={(e: DropDownListChangeEvent) => {
                  setAttachForm({ ...attachForm, roleDest: e.target.value });
                }}
                className="mb-3"
              />
            )}
        </div>
        <StyledSquaredAddButtonWrapper>
          <SquaredAddButton
            isValid={isValid()}
            handleOnClick={() => {
              onChange(addAction('form_permission_change', getFormPermissionArgs(), actions));
              setAttachForm(initAttachForm());
            }}
            label={t('Add permission')}
          />
        </StyledSquaredAddButtonWrapper>
      </div>
    </div>
  );
};

export default FormPermissionPicker;

const StyledSquaredAddButtonWrapper = styled.div`
  width: 100%;

  button {
    margin: 0;
  }

  svg {
    height: 2.2rem;
  }
`;
