import { PaperStatusName } from '../store/paper-status/types';
import { TFunction } from 'i18next';
import { FTypeEnum } from '../components/FormBuilder/FormBuilder';
import { Role } from '../store/conference/types';
import { FormVisibilityOption, ValidationStatus } from '../store/form/types';
import { PermissionString } from '../store/permission/types';
import { capitalizeFirstLetter } from './string';
import { TableColumnId, TableViewName } from '../store/table/types';
import { MenuItemName } from '../layouts/types';
import { InvitationStatus } from '../store/auth/types';

export const getPaperStatusName = (name: PaperStatusName, t: TFunction<'translation', undefined>): string => {
  let ret = '';
  switch (name) {
    case 'Withdrawn':
      ret = t('Withdrawn');
      break;
    case 'Deleted':
      ret = t('Deleted');
      break;
    case 'Awaiting decision':
      ret = t('Awaiting decision');
      break;
    default:
      ret = name;
  }
  return ret;
};

export const getFormName = (name: string, isDefault: boolean, t: TFunction<'translation', undefined>): string => {
  return isDefault ? t('Default Submission form') : name;
};

export const getQuestionTypeName = (name: string, t: TFunction<'translation', undefined>): string => {
  switch (name) {
    case FTypeEnum.Select:
      return t('Single-Choice select');
    case FTypeEnum.MultipleSelect:
      return t('Multiple-Choice select');
    case FTypeEnum.LikertScale:
      return t('Likert scale (Single-Choice select with numerical value)');
    case FTypeEnum.Numeric:
      return t('Numeric');
    case FTypeEnum.ShortText:
      return t('Short text');
    case FTypeEnum.Text:
      return t('Long text');
    case FTypeEnum.File:
      return t('File upload');
    case FTypeEnum.LinearScale:
      return t('Linear scale');
    default:
      return name;
  }
};

export const getRoleName = (role: Role, t: TFunction<'translation', undefined>): string => {
  switch (role.type) {
    case 'author':
      return t('Author');
    case 'chair':
      return t('Chair');
    default:
      return role.description;
  }
};

export const getPhaseTypeName = (type: PhaseType, t: TFunction<'translation', undefined>): string => {
  switch (type) {
    case 'author_notification':
      return t('Author notification');
    case 'bidding':
      return t('Bidding');
    case 'custom':
      return t('Custom');
    case 'discussion':
      return t('Discussion');
    case 'review':
      return t('Review');
    case 'submission':
      return t('Submission');
    default:
      return type;
  }
};

export const getEventTypeName = (name: PhaseEventType, t: TFunction<'translation', undefined>): string => {
  switch (name) {
    case 'custom':
      return t('Custom');
    case 'end':
      return t('End');
    case 'start':
      return t('Start');
  }
};

export const getVisibilityOptionName = (
  option: FormVisibilityOption,
  t: TFunction<'translation', undefined>,
): string => {
  switch (option) {
    case 'hidden':
      return t("can't view");
    case 'read':
      return t('can read-only');
    case 'write':
      return t('can read/write');
  }
};

export const getPermissionName = (value: PermissionString, t: TFunction<'translation', undefined>): string => {
  switch (value) {
    case 'IS_MODERATOR_OF_DISCUSSION':
      return t('Is moderator of discussion');
    default:
      return value
        .toLowerCase()
        .split('_')
        .map((split) => capitalizeFirstLetter(split))
        .join(' ');
  }
};

export const getValidationStatusName = (status: ValidationStatus, t: TFunction<'translation', undefined>): string => {
  switch (status) {
    case 'Complete':
      return t('Complete');
    case 'Pending':
      return t('Incomplete');
  }
};

export const getTableColumnName = (
  fieldId: TableColumnId,
  t: TFunction<'translation', undefined>,
): string | undefined => {
  switch (fieldId) {
    case 'person_id':
      return t('ID');
    case 'dblp_key':
      return t('DBLP ID');
    case 'google_scholar_id':
      return t('Google scholar ID');
    case 'orcid':
      return t('ORCID');
    case 'email':
      return t('Email');
    case 'first_name':
      return t('First name');
    case 'last_name':
      return t('Last name');
    case 'organization':
      return t('Organization');
    case 'country':
      return t('Country');
    case 'roles':
      return t('Roles');
    case 'keywords':
      return t('Keywords');
    case 'main_area':
      return t('Main area');
    case 'labels':
      return t('Labels');
    case 'id':
      return t('ID');
    case 'role':
      return t('Role');
    case 'status':
      return t('Status');
    case 'comment':
      return t('Comment');
    case 'expiration_date':
      return t('Expiration date');
    case 'body':
      return t('Message');
    case 'title':
      return t('Title');
    case 'abstract':
      return t('Abstract');
    case 'authors_str':
      return t('Authors');
    case 'file_upload':
      return t('Paper upload');
    case 'validation_status':
      return t('Validation status');
    case 'paper_status':
      return t('Paper status');
    case 'person_role_id':
      return t('PersonRole ID');
    case 'name':
      return t('Full name');
    case 'completed':
      return t('Completed');
    case 'bids':
      return '#' + ' ' + t('Bids');
    case 'positive':
      return '#' + ' ' + t('Positive');
    case 'negative':
      return '#' + ' ' + t('Negative');
    case 'total_reviews':
      return '#' + ' ' + t('Assignments');
    case 'completed_reviews':
      return '#' + ' ' + t('Completed reviews');
    case 'percent_completed_reviews':
      return '%' + ' ' + t('Completed reviews');
    case 'bid':
      return t('Bid');
    case 'conflict':
      return t('Conflict');
    case 'assigned':
      return t('Assigned');
    case 'affinity':
      return t('Affinity');
    default:
      return undefined;
  }
};

export const getMenuItemName = (name: MenuItemName, t: TFunction<'translation', undefined>): string => {
  switch (name) {
    case 'Announcements':
      return t('Announcements');
    case 'Assignments':
      return t('Assignments');
    case 'Bidding':
      return t('Bidding');
    case 'Bulk import':
      return t('Bulk import');
    case 'Chair settings':
      return t('Chair settings');
    case 'Communications':
      return t('Communications');
    case 'Conference':
      return t('Conference');
    case 'Dashboard':
      return t('Dashboard');
    case 'Email history':
      return t('Email history');
    case 'Email templates':
      return t('Email templates');
    case 'Forms':
      return t('Forms');
    case 'Hide menu':
      return t('Hide menu');
    case 'Keywords':
      return t('Keywords');
    case 'Paper status':
      return t('Paper status');
    case 'Papers to reviewers':
      return t('Papers to reviewers');
    case 'People':
      return t('People');
    case 'Planning':
      return t('Planning');
    case 'Recruitment':
      return t('Recruitment');
    case 'Reviewers bids':
      return t('Reviewers bids');
    case 'Reviewers to papers':
      return t('Reviewers to papers');
    case 'Roles':
      return t('Roles');
    case 'Submissions':
      return t('Submissions');
    case 'Submissions bids':
      return t('Submissions bids');
    case 'User keywords':
      return t('User keywords');
    default:
      return name;
  }
};

export const getTableViewName = (name: TableViewName, t: TFunction<'translation', undefined>): string => {
  switch (name) {
    case 'Basic config':
      return t('Basic config');
    default:
      return name;
  }
};

export const getActionName = (type: PhaseActionType, t: TFunction<'translation', undefined>): string => {
  switch (type) {
    case 'bidding_access':
      return t('Allow reviewers to view bidding');
    case 'bidding_edit':
      return t('Allow reviewers to edit bidding');
    case 'change_paper_status':
      return t('Allow reviewers to change paper status');
    case 'discussion_enabled':
      return t('Allow reviewers to post messages in discussions');
    case 'submission_abstract_edition':
      return t('Allow abstract edition for authors');
    case 'submission_author_list_edition':
      return t('Allow authors edition for authors');
    case 'submission_creation':
      return t('Allow submission creation for authors');
    case 'submission_keywords_edition':
      return t('Allow keywords edition for authors');
    case 'submission_paper_upload':
      return t('Allow paper upload for authors');
    case 'submission_title_edition':
      return t('Allow title edition for authors');
    default:
      return '';
  }
};

export const getInvitationStatusName = (status: InvitationStatus, t: TFunction<'translation', undefined>): string => {
  switch (status) {
    case 'Accepted':
      return t('Accepted');
    case 'Declined':
      return t('Declined');
    case 'Not responded':
      return t('Not responded');
    case 'Send failed':
      return t('Send failed');
  }
};
