import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BidDTO, BidState, ConflictDTO, GetBidOptionsResponse, GetBidsResponse } from './types';

const initialState: BidState = {
  loading: false,
  bidOptions: {
    byId: {},
    loading: false,
  },
  byId: {},
};

const bidSlice = createSlice({
  name: 'BID',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET:BID_OPTIONS']: (state) => ({
      ...state,
      bidOptions: {
        ...state.bidOptions,
        loading: true,
      },
    }),
    ['GET:BID_OPTIONS:OK']: (state, action: PayloadAction<GetBidOptionsResponse>) => ({
      ...state,
      bidOptions: {
        ...state.bidOptions,
        loading: false,
        byId: action.payload.data,
      },
    }),
    ['GET:BIDS:OWN']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET:BIDS:OWN:OK']: (state, action: PayloadAction<GetBidsResponse>) => ({
      ...state,
      loading: false,
      byId: action.payload.data,
    }),
    ['POST:BID']: (state, action: PayloadAction<BidDTO>) => ({
      ...state,
    }),
    ['POST:BID:OK']: (state, action: PayloadAction<BidDTO>) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.submission_id]: {
          ...state.byId[action.payload.submission_id],
          ...action.payload,
          conflict: !action.payload.bid_option_id,
        },
      },
    }),
    ['POST:CONFLICT']: (state, action: PayloadAction<ConflictDTO>) => ({
      ...state,
    }),
    ['POST:CONFLICT:OK']: (state, action: PayloadAction<ConflictDTO>) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.submission_id]: {
          ...state.byId[action.payload.submission_id],
          conflict: action.payload.conflict,
          bid_option_id: action.payload.conflict ? null : state.byId[action.payload.submission_id].bid_option_id,
        },
      },
    }),
  },
});

export default bidSlice;
