import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import { getReviewsResponse, AssignmentsAdditionalInfo, UpdateAssignmentDTO, AssignmentDetailsDTO } from './types';
import { put, takeLatest } from '@redux-saga/core/effects';
import reviewSlice from './slice';
import errorSlice from '../error/slice';
import formSlice from '../form/slice';
import { FileUploadUpdaterDTO, UploadFeedbackResponse } from '../submission/types';
import { PayloadAction } from '@reduxjs/toolkit';

const baseURL = process.env.REACT_APP_API_URL;

export const postReviews = async (
  data: FileUploadUpdaterDTO,
): Promise<Result<UploadFeedbackResponse, APIValidationError>> => {
  const { file, dry_run } = data;

  const formData = new FormData();
  formData.append('file', file);

  const init = {
    method: 'POST',
    auth: true,
    role: true,
    defaultContentType: true,
    body: formData,
  };

  return apiCall<UploadFeedbackResponse>(`${baseURL}/api/assignments/bulk?dry_run=${dry_run ? 1 : 0}`, init);
};

const getReviews = async (): Promise<Result<getReviewsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getReviewsResponse>(`${baseURL}/api/assignments/current`, init);
};

function* getReviewsSaga() {
  const result = (yield getReviews()) as Await<ReturnType<typeof getReviews>>;

  switch (result.type) {
    case 'ok':
      const { answerById } = result.value.data;
      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      yield put(reviewSlice.actions['GET_REVIEWS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const getAssignmentDetails = async (submissionId: number): Promise<Result<getReviewsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getReviewsResponse>(`${baseURL}/api/assignments/${submissionId}`, init);
};

function* getAssignmentDetailsSaga(action: PayloadAction<number>) {
  const result = (yield getAssignmentDetails(action.payload)) as Await<ReturnType<typeof getAssignmentDetails>>;

  switch (result.type) {
    case 'ok':
      const { answerById } = result.value.data;
      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      yield put(reviewSlice.actions['GET_REVIEWS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export const getAssignmentStatus = async (
  data: AssignmentDetailsDTO,
): Promise<Result<{ data: AssignmentsAdditionalInfo[] }, APIValidationError>> => {
  const { submission_id, person_role_id, target_role_id } = data;
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  let params = '';
  if (submission_id) {
    params = `submission_id=${submission_id}&target_role_id=${target_role_id}`;
  } else if (person_role_id) {
    params = `person_role_id=${person_role_id}`;
  }

  return apiCall<{ data: AssignmentsAdditionalInfo[] }>(`${baseURL}/api/assignments/status?${params}`, init);
};

export const updateAssignment = async (
  data: UpdateAssignmentDTO,
): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/assignments`, init);
};

export default [
  takeLatest(reviewSlice.actions['GET_REVIEWS'], getReviewsSaga),
  takeLatest(reviewSlice.actions['GET_ASSIGNMENT_DETAILS'], getAssignmentDetailsSaga),
];
