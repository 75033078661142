import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { selectInfo, selectKeywordState, selectTrackRoles } from '../../store/selectors';
import { Role } from '../../store/conference/types';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import infoSlice from '../../store/info/slice';
import Loading from '../../components/Loading/Loading';
import { formatKeywordColumn } from '../../helpers/table';
import { useTranslation } from 'react-i18next';
import { ReviewerBidTableRow } from '../../store/bid/types';

interface Props {
  getInfoAction: (data: InfoGetDTO) => void;
  info: Info;
  loading: boolean;
  roleById: { [key: string]: Role };
  keywordById: { [key: number]: Keyword };
}

const AllReviewersBiddingPage: React.FC<Props> = ({ getInfoAction, info, loading, roleById, keywordById }) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'reviewers_bids';

  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    }
    if (Object.keys(info[friendlyName].byId).length === 0) {
      getInfoAction({ friendlyName });
    }
  }, []);

  if (loading || !Object.keys(info['users'].byId).length) {
    return <Loading />;
  }

  const chairInfo = info as ChairInfo;

  /* Build input data */
  const inputData: unknown[] = [];
  Object.values(chairInfo.users.byId).forEach((register) => {
    register.role_ids.forEach((roleId, index) => {
      const role = roleById[roleId];
      const personRoleId = register.person_role_ids[index];
      if (role.type == 'reviewer') {
        const newRegister = {
          ...register,
          id: personRoleId,
          role_id: roleId,
          person_role_id: personRoleId,
          ...chairInfo.reviewers_bids.byId[personRoleId], // Add bidding information
        } as ReviewerBidTableRow;

        // Add Role description
        newRegister.role = roleById[roleId].description;

        formatKeywordColumn(newRegister, keywordById);

        inputData.push(newRegister);
      }
    });
  });

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Reviewers bids')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={() => getInfoAction({ friendlyName })}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  info: state.info.info,
  loading: selectInfo(state).loading,
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllReviewersBiddingPage);
