import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <path
        stroke="#1E44F0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 4.5h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-12c0-1.1.9-2 2-2z"
      ></path>
      <path stroke="#1E44F0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M22 6.5l-10 7-10-7"></path>
    </svg>
  );
}

export default Icon;
