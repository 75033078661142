import { PersonTableRow } from '../store/info/types';
import { ReviewerTableRow } from '../store/bid/types';

export const getReviewer = (
  personRoleId: number,
  users: {
    byId: { [key: number]: PersonTableRow };
    personRoleIdMap: { [key: number]: number };
  },
): ReviewerTableRow | undefined => {
  const person_id = users.personRoleIdMap[personRoleId];
  const reviewer = users.byId[person_id];
  if (!reviewer) return undefined;
  const index = reviewer.person_role_ids.findIndex((value) => value == personRoleId);
  return {
    ...reviewer,
    id: personRoleId,
    person_role_id: personRoleId,
    role_id: reviewer.role_ids[index],
  } as ReviewerTableRow;
};
