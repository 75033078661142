import React, { useRef } from 'react';
import tw from 'twin.macro';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { TextField, TextFieldProps } from '@material-ui/core';

export type InputTextProps = TextFieldProps & {
  label?: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  ref?: any;
  disabled?: boolean;
  inputProps?: any;
};

export const InputText = ({
  id,
  label,
  fullWidth,
  error = false,
  type,
  variant = 'outlined',
  name,
  value,
  defaultValue,
  autoFocus,
  helperText,
  disabled,
  required,
  className,
  InputProps,
  placeholder,
  inputProps,
  onChange,
  onBlur,
}: InputTextProps): JSX.Element => {
  const inputId = id || nanoid();
  const theme = useTheme();

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className={cn(className, { error, fullWidth })}>
        {label && (
          <label htmlFor={inputId} className={`${error ? 'text-red-600' : theme.palette.text.primary}`}>
            {required ? `${label} *` : label}
          </label>
        )}

        <StyledInput>
          <TextField
            id={id}
            error={error}
            type={type}
            name={name}
            variant="outlined"
            autoFocus={autoFocus}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            InputProps={InputProps}
            fullWidth={true}
            placeholder={placeholder}
            disabled={disabled}
            inputProps={inputProps}
            inputRef={inputRef}
            onClick={() => {
              if (type === 'number' && inputRef.current) {
                inputRef.current.focus();
              }
            }}
          />
          <span className={cn('helperText pt-1.5', { error })}>{helperText}</span>
        </StyledInput>
      </StyledDiv>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`inline-flex flex-col`}

  label {
    ${tw`mb-1.5 text-sm`}
    font-weight: normal;
  }

  input {
    ${tw`rounded`}
    padding: 9px;
    background-color: ${(props) => props.theme.palette.background.paper};

    border: ${(props) => '1px solid ' + props.theme.palette.secondary.dark}};
    --tw-ring-color: ${(props) => props.theme.palette.text.primary};

    &.focus {
      border-color: ${(props) => props.theme.palette.text.primary};
      --tw-ring-color: ${(props) => props.theme.palette.text.primary};
    }
  }

  .helperText {
    ${tw`text-sm m-0.5 mb-1 leading-4 h-4`}
    color:  ${(props) => props.theme.palette.text.secondary};
    display: none;

    &.error {
      color: ${(props) => props.theme.palette.error.main};
      display: block;
    }
  }

  &.error {
    label {
      color: ${(props) => props.theme.palette.error.main};
    }

    input {
      ${tw`rounded border-2`}
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }

  &.fullWidth {
    ${tw`flex flex-row`}

    label {
      width: 10rem;
      padding-top: 0.375rem;
    }
  }
`;

const StyledInput = styled.div`
  ${tw`w-full`}
`;
