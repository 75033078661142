import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g stroke="#2D53F1" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_100_16)">
        <path d="M13.333 6h-6C6.597 6 6 6.597 6 7.333v6c0 .737.597 1.334 1.333 1.334h6c.737 0 1.334-.597 1.334-1.334v-6c0-.736-.597-1.333-1.334-1.333z"></path>
        <path d="M3.333 10h-.666a1.333 1.333 0 01-1.333-1.333v-6a1.333 1.333 0 011.333-1.334h6A1.333 1.333 0 0110 2.667v.666"></path>
      </g>
      <defs>
        <clipPath id="clip0_100_16">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
