import React, { useEffect } from 'react';
import Loading from '../../components/Loading/Loading';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import { AssignmentSummary, ReviewerAssignmentTableRow } from '../../store/review/types';
import { Role } from '../../store/conference/types';
import { AppState } from '../../store/state';
import { selectInfo, selectKeywordState, selectTrackRoles } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import infoSlice from '../../store/info/slice';
import { connect } from 'react-redux';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { formatKeywordColumn } from '../../helpers/table';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  info: Info;
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  keywordById: { [key: number]: Keyword };
}

const ReviewersToPapersPage: React.FC<Props> = ({ loading, info, roleById, getInfoAction, keywordById }) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'reviews_to_papers';

  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    }
    if (Object.keys(info[friendlyName].byId).length === 0) {
      getInfoAction({ friendlyName });
    }
  }, []);

  if (loading || !Object.keys(info['users'].byId).length) {
    return <Loading />;
  }

  const chairInfo = info as ChairInfo;

  /* Build input data */
  const inputData: unknown[] = [];
  Object.values(chairInfo.users.byId).forEach((register) => {
    register.role_ids.forEach((roleId, index) => {
      const role = roleById[roleId];
      const personRoleId = register.person_role_ids[index];
      if (role.type == 'reviewer') {
        let summary: AssignmentSummary;
        if (personRoleId in chairInfo.reviews_to_papers.byId) {
          summary = chairInfo.reviews_to_papers.byId[personRoleId].reviews_summary;
        } else {
          summary = { total_reviews: 0, completed_reviews: 0, percent_completed_reviews: 0 };
        }

        const newRegister = {
          ...register,
          id: personRoleId,
          role_id: roleId,
          person_role_id: personRoleId,
          ...summary, // Add assignments information
        } as ReviewerAssignmentTableRow;

        // Add Role description
        newRegister.role = roleById[roleId].description;

        formatKeywordColumn(newRegister, keywordById);

        inputData.push(newRegister);
      }
    });
  });

  return (
    <>
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Reviewers to papers')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={() => getInfoAction({ friendlyName })}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading,
  info: state.info.info,
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewersToPapersPage);
