import { NotifiablesUpdateDTO } from '../table/types';
import { AssignmentsAdditionalInfo, UpdateAssignmentDTO } from './types';
import React, { useState } from 'react';
import { getAssignmentStatus, updateAssignment } from './sagas';
import { DeleteInfoDTO } from '../info/types';

export const useAssignmentDetail = (
  field: 'person_role_id' | 'submission_id',
  value: number,
  updateNotifiablesAction: (data: NotifiablesUpdateDTO) => void,
  deleteInfoAction: (data: DeleteInfoDTO) => void,
  roleId?: number,
): [
  AssignmentsAdditionalInfo[],
  boolean,
  (onOk?: () => void) => void,
  (modelId: number, assigned: boolean) => void,
] => {
  const [data, setData] = React.useState<AssignmentsAdditionalInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = () => {
    getAssignmentStatus({ [field]: value, target_role_id: roleId }).then((result) => {
      if (result.type === 'ok') {
        setData(result.value.data);
        setIsLoading(false);
      }
    });
  };

  const updateAssignmentAction = (modelId: number, assigned: boolean) => {
    const dto: UpdateAssignmentDTO = { submission_id: 0, person_role_id: 0, assigned };
    dto[field] = value;
    const keyBy: 'person_role_id' | 'submission_id' = field === 'person_role_id' ? 'submission_id' : 'person_role_id';
    dto[keyBy] = modelId;
    // Make API call
    updateAssignment(dto).then((result) => {
      if (result.type === 'ok') {
        setData(
          data.map((value) => {
            if (value.person_role_id == dto.person_role_id && value.submission_id == dto.submission_id) {
              return {
                ...value,
                assigned,
                total_reviews: assigned ? value.total_reviews + 1 : value.total_reviews - 1,
              };
            } else {
              return value;
            }
          }),
        );
        updateNotifiablesAction({ ...dto, keyBy });
        // Invalidate already fetched data related with assignments
        deleteInfoAction({ tableFriendlyName: 'reviews_to_papers' });
        deleteInfoAction({ tableFriendlyName: 'papers_to_reviews' });
      }
    });
  };

  return [data, isLoading, fetchData, updateAssignmentAction];
};
