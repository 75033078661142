import { CircularProgress } from '@material-ui/core';
import React from 'react';

interface Props {
  text?: string;
  compact?: boolean;
}

const Loading: React.FC<Props> = ({ text, compact }) => {
  return (
    <div className={`flex items-center justify-center w-full flex-col ${compact ? '' : 'min-h-screen'}`}>
      <CircularProgress color="primary" />
      {text && <p className="text-sm mt-2 text-black">{text}</p>}
    </div>
  );
};

export default Loading;
