import { getReviewsResponse, ReviewState, ValidationStatusDTO, viewModeType } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: ReviewState = {
  loading: false,
  reviewsById: {},
  viewMode: 'cards',
};

const reviewSlice = createSlice({
  name: 'REVIEW',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_REVIEWS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_REVIEWS:OK']: (state, action: PayloadAction<getReviewsResponse>) => ({
      ...state,
      loading: false,
      reviewsById: action.payload.data.reviewsById,
    }),
    ['GET_ASSIGNMENT_DETAILS']: (state, action: PayloadAction<number>) => ({
      ...state,
      loading: true,
    }),
    ['UPDATE_STATUS']: (state, action: PayloadAction<ValidationStatusDTO>) => ({
      ...state,
      reviewsById: {
        ...state.reviewsById,
        [action.payload.review_id]: {
          ...state.reviewsById[action.payload.review_id],
          validation_status: action.payload.validation_status,
        },
      },
    }),
    ['CHANGE_VIEWMODE']: (state, action: PayloadAction<viewModeType>) => {
      return {
        ...state,
        viewMode: action.payload,
      };
    },
  },
});

export default reviewSlice;
