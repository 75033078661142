import React, { useEffect } from 'react';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import { Submission } from '../../store/submission/types';
import { Role } from '../../store/conference/types';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { AppState } from '../../store/state';
import {
  selectBidState,
  selectInfo,
  selectKeywordState,
  selectLabelState,
  selectPaperStatusState,
  selectSubmissionState,
  selectTable,
  selectTrackRoles,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import infoSlice from '../../store/info/slice';
import submissionSlice from '../../store/submission/slice';
import { connect } from 'react-redux';
import { BidOption } from '../../store/bid/types';
import { Label } from '../../store/label/types';
import { TableSettings } from '../../components/MyGridRedux/types';
import Loading from '../../components/Loading/Loading';
import { PaperStatus } from '../../store/paper-status/types';
import { difference } from '../../helpers/set';
import { formatKeywordColumn } from '../../helpers/table';
import { useTranslation } from 'react-i18next';
import { getPaperStatusName, getTableColumnName } from '../../helpers/translations';
import { AssignmentSummary, SubmissionAssignmentTableRow } from '../../store/review/types';

interface Props {
  loading: boolean;
  info: Info;
  submissionsById: { [key: string]: Submission };
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  getSubmissionsAction: () => void;
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  bidOptionsById: { [key: number]: BidOption };
  labelsById: { [key: number]: Label };
  labelables: { [key: string]: { [key: number]: number[] } };
  tablesSettings: { [key: string]: TableSettings };
}

const PapersToReviewersPage: React.FC<Props> = ({
  loading,
  info,
  submissionsById,
  roleById,
  getInfoAction,
  getSubmissionsAction,
  keywordById,
  paperStatusById,
  tablesSettings,
}) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'papers_to_reviews';

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction();
    }
    if (Object.keys(info[friendlyName].byId).length === 0) {
      getInfoAction({ friendlyName });
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  const chairInfo = info as ChairInfo;

  const reviewerRolesById: { [key: string]: Role } = {};
  Object.values(roleById)
    .filter((role) => role.type == 'reviewer')
    .forEach((role) => {
      reviewerRolesById[role.id] = role;
    });

  const tableSettings = tablesSettings[friendlyName];

  // Add summary reviews fields for each role
  const gridColumnProps = [...tableSettings.gridSettings.gridColumnProps];
  const current = gridColumnProps
    .filter((columnProps) => columnProps.id.startsWith('reviews_summary_'))
    .map((columnProps) => parseInt(columnProps.id.split('_')[2]));
  const expected = Object.keys(reviewerRolesById).map((roleId) => parseInt(roleId));
  const missing = Array.from(difference(new Set(expected), new Set(current)));
  let maxOrderIndex = Math.max(...gridColumnProps.map((columnProp) => columnProp.orderIndex));
  missing.forEach((roleId) => {
    const id = `reviews_summary_${roleId}`;
    gridColumnProps.push({
      id,
      locked: false,
      show: true,
      orderIndex: ++maxOrderIndex,
      children: [],
    });
  });

  const newTableSettings = {
    ...tableSettings,
    gridSettings: {
      ...tableSettings.gridSettings,
      gridColumnProps: gridColumnProps.map((columnProps, index) => {
        const newColumnProps = { ...columnProps };
        if (columnProps.id.startsWith('reviews_summary_')) {
          const roleId = parseInt(columnProps.id.split('_')[2]);
          newColumnProps['title'] = t('{{roleName}} reviews summary', { roleName: roleById[roleId].description });
          newColumnProps['children'] = [
            {
              field: `reviews_summary.${roleId}.total_reviews`,
              title: getTableColumnName('total_reviews', t),
              width: '175px',
              filter: 'numeric',
            },
            {
              field: `reviews_summary.${roleId}.completed_reviews`,
              title: getTableColumnName('completed_reviews', t),
              width: '175px',
              filter: 'numeric',
            },
            {
              field: `reviews_summary.${roleId}.percent_completed_reviews`,
              title: getTableColumnName('percent_completed_reviews', t),
              width: '175px',
              filter: 'numeric',
            },
          ];
        }
        return newColumnProps;
      }),
    },
  };

  const inputData: unknown[] = [];
  Object.values(submissionsById).forEach((submission, index) => {
    const newRegister = {
      ...submission,
      reviews_summary: {},
    } as SubmissionAssignmentTableRow;

    // Add assignments information
    Object.values(reviewerRolesById).forEach((role) => {
      let summary: AssignmentSummary;
      if (
        submission.id in chairInfo.papers_to_reviews.byId &&
        role.id in chairInfo.papers_to_reviews.byId[submission.id].reviews_summary
      ) {
        summary = chairInfo.papers_to_reviews.byId[submission.id].reviews_summary[role.id];
      } else {
        summary = { total_reviews: 0, completed_reviews: 0, percent_completed_reviews: 0 };
      }
      newRegister.reviews_summary[role.id] = summary;
    });

    formatKeywordColumn(newRegister, keywordById);

    // @ts-ignore
    newRegister.authors_str = newRegister.authors.map((author) => author.first_name + ' ' + author.last_name).join(';');
    newRegister.paper_status = getPaperStatusName(paperStatusById[newRegister.paper_status_id].name, t);

    // @ts-ignore
    newRegister.file_upload_str = newRegister.file_upload?.filename_original;

    inputData.push(newRegister);
  });

  return (
    <>
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Papers to reviewers')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            initTableSettings={newTableSettings}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={() => getInfoAction({ friendlyName })}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading || selectSubmissionState(state).loading,
  info: state.info.info,
  submissionsById: selectSubmissionState(state).submissionsById,
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  bidOptionsById: selectBidState(state).bidOptions.byId,
  labelsById: selectLabelState(state).labelsById,
  labelables: selectLabelState(state).labelables,
  tablesSettings: selectTable(state).tablesSettings,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  getSubmissionsAction: () => dispatch(submissionSlice.actions.GET_SUBMISSIONS()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PapersToReviewersPage);
