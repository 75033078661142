import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#000' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 16 16">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14 6.667c0 4.666-6 8.666-6 8.666s-6-4-6-8.666a6 6 0 1 1 12 0"
      ></path>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M8 8.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4"></path>
    </svg>
  );
}

export default Icon;
