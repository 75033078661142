import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Link } from '../ui';
import { useTranslation } from 'react-i18next';

interface Props extends GridCellProps {
  handleViewClick: (rowId: number) => void;
}

const BodyCell: React.FC<Props> = ({ handleViewClick, ...props }) => {
  const { t, i18n } = useTranslation();
  const id = props.dataItem['id'];
  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      <Link onClick={() => handleViewClick(id)}>{t('View')}</Link>
    </td>
  );
};

export default BodyCell;
