import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import bidSlice from '../store/bid/slice';
import { selectBidState, selectKeywordState } from '../store/selectors';
import Loading from '../components/Loading/Loading';
import { BidRow, BidDTO, BidOption, ConflictDTO } from '../store/bid/types';
import { can } from '../helpers/permissions';
import MyTableGridWrapper from '../components/MyTableGridWrapper/MyTableGridWrapper';
import styled from 'styled-components';
import { formatKeywordColumn } from '../helpers/table';
import SimpleProgressBar from '../components/ui/display/SimpleProgressBar/SimpleProgressBar';
import MyGridCore from '../components/MyGridCore/MyGridCore';
import { GridCellProps, GridColumnProps, GridFilterCellProps } from '@progress/kendo-react-grid';
import KeywordsCell from '../components/KeywordsCell/KeywordsCell';
import BidCell from '../components/BidCell/BidCell';
import { MultiSelectFilterCell } from '../components/MultiSelectFilterCell/MultiSelectFilterCell';
import ConflictCell from '../components/ConflictCell/ConflictCell';
import { DropdownFilterCell } from '../components/DropdownFilterCell/DropdownFilterCell';
import { DEFAULT_FONT_SIZE } from '../components/MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';
import AbstractCell from '../components/AbstractCell/AbstractCell';
import { getInitialDataState } from '../store/table/slice';

interface Props {
  loading: boolean;
  bidsById: { [key: number]: BidRow };
  bidOptionsById: { [key: number]: BidOption };
  keywordById: { [key: number]: Keyword };
  getOwnBidsAction: () => void;
  createBidAction: (data: BidDTO) => void;
  postConflictAction: (data: ConflictDTO) => void;
}

const BiddingPage: React.FC<Props> = ({
  loading,
  bidsById,
  bidOptionsById,
  keywordById,
  getOwnBidsAction,
  createBidAction,
  postConflictAction,
}) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (Object.keys(bidsById).length === 0) {
      getOwnBidsAction();
    }
  }, []);

  if (loading) return <Loading />;

  // Calculate the progress bar value based on: the number of bids of the selected option, the minimum bids, and the maximum bids.
  const calculateProgressBarValue = (bidsCount: number, min: number | null, max: number | null): number => {
    let divisor: number;

    let value;
    if (!bidsCount) {
      // When bids selected are zero, shows a little portion of bar (3%)
      value = 0.03;
    } else if (max) {
      // If bids' max is limited
      divisor = max;
      value = bidsCount / divisor;
    } else if (min) {
      // If bids' min is limited
      // If bidsCount is greater than the lower limit, the progress bar will keep growing but will never reach the end.
      divisor = bidsCount > min ? bidsCount + 1 : min + 1;
      value = bidsCount / divisor;
    } else {
      // If bids' max and bids' min are unlimited
      // Progress bar starts at 90% and keeps growing but will never reach the end.
      const increment = bidsCount - 1;
      value = (9 + increment) / (10 + increment);
    }
    return value * 100;
  };

  // Initialize bid counts
  const counts: { [key: number]: number } = {};
  Object.values(bidOptionsById).forEach((bidOption) => {
    counts[bidOption.id] = 0;
  });

  // Initialize grid data
  const inputData: any[] = [];
  Object.values(bidsById).forEach((register) => {
    const newRegister = { ...register };

    formatKeywordColumn(newRegister, keywordById);

    // @ts-ignore
    newRegister.authors_str = newRegister.authors.map((author) => author.first_name + ' ' + author.last_name).join(';');
    inputData.push(newRegister);

    // Add count
    if (register.bid_option_id) {
      counts[register.bid_option_id] += 1;
    }
  });

  let gridColumnProps: GridColumnProps[] = [
    { field: 'submission_external_id', title: t('ID'), filter: 'numeric', width: '150px', locked: true },
    { field: 'title', title: t('Title'), width: '200px' },
    {
      field: 'abstract',
      title: t('Abstract'),
      width: '500px',
      cell: (props: GridCellProps) => <AbstractCell {...props} />,
    },
    { field: 'affinity', title: t('Affinity'), width: '200px', filter: 'numeric' },
  ];
  const canViewAuthors = !!Object.values(bidsById).filter((bid) => bid.authors.length > 0).length;
  if (canViewAuthors) {
    gridColumnProps.push({ field: 'authors_str', title: t('Authors'), width: '200px' });
  }
  if (Object.keys(keywordById).length > 0) {
    gridColumnProps.push({
      field: 'keywords',
      title: t('Keywords'),
      width: '200px',
      cell: (props: GridCellProps) => <KeywordsCell {...props} keywordById={keywordById} />,
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    });
    gridColumnProps.push({
      field: 'main_area',
      title: t('Main area'),
      width: '200px',
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    });
  }
  gridColumnProps = gridColumnProps.concat([
    {
      field: 'bid_option_id',
      title: t('Bid'),
      width: '200px',
      cell: (props: GridCellProps) => (
        <BidCell {...props} bidOptionsById={bidOptionsById} createBid={createBidAction} />
      ),
      filterCell: (props: GridFilterCellProps) => (
        <DropdownFilterCell
          {...props}
          data={Object.values(bidOptionsById)
            .map((value) => ({ id: value.id.toString(), label: value.name }))
            // @ts-ignore
            .concat([{ id: null, label: t('None') }])}
          defaultItem={{ label: t('(All)') }}
        />
      ),
    },
    {
      field: 'conflict',
      title: t('Conflict'),
      width: '200px',
      filter: 'boolean',
      cell: (props: GridCellProps) => <ConflictCell {...props} postConflict={postConflictAction} />,
    },
  ]);

  return (
    <div className="h-full">
      {can('CAN_EDIT_BID') ? (
        <div className="h-full flex flex-col relative">
          <h2 className="text-xl mb-3 font-bold text-gray-700">{t('Submissions to bid')}</h2>

          {/* Bid options status with progress bar */}
          {inputData.length > 0 && (
            <StyledBidStatusWrapper className="mt-4 mb-8 flex">
              {Object.values(bidOptionsById).map((bidOption, index) => {
                const count = counts[bidOption.id];

                let bidsBarColor;
                let bgColor;
                if (
                  count >= (bidOption.min ?? Number.NEGATIVE_INFINITY) &&
                  count <= (bidOption.max ?? Number.POSITIVE_INFINITY)
                ) {
                  bidsBarColor = 'green-600';
                  bgColor = ''; //#c2f0e1
                } else {
                  bidsBarColor = 'red-600';
                  bgColor = ''; //#f9d8d8
                }

                let expectedBids = '';
                if (bidOption.min && bidOption.max) {
                  expectedBids =
                    bidOption.min > 1
                      ? t('You are required a min. of {{min}} bids and max. of {{max}}', {
                          min: bidOption.min,
                          max: bidOption.max,
                        })
                      : t('You are required a min. of {{min}} bid and max. of {{max}}', {
                          min: bidOption.min,
                          max: bidOption.max,
                        });
                } else if (bidOption.min) {
                  expectedBids =
                    bidOption.min > 1
                      ? t('You are required at least {{min}} bids', {
                          min: bidOption.min,
                        })
                      : t('You are required at least {{min}} bid', {
                          min: bidOption.min,
                        });
                } else if (bidOption.max) {
                  expectedBids =
                    bidOption.max > 1
                      ? t('You are required at most {{max}} bids', {
                          max: bidOption.max,
                        })
                      : t('You are required at most {{max}} bid', {
                          max: bidOption.max,
                        });
                } else {
                  expectedBids = t('Bids unlimited');
                }

                const value = calculateProgressBarValue(count, bidOption.min, bidOption.max);

                return (
                  <div
                    key={index}
                    className="relative bidOPtion rounded-md p-2"
                    style={{ width: '20rem', backgroundColor: bgColor }}
                  >
                    <h3 className="text-sm font-semibold">{bidOption.name}</h3>
                    <p className="text-xs mb-3">{expectedBids}</p>

                    <SimpleProgressBar barColor={`bg-${bidsBarColor}`} progressValue={value} />

                    {/* SCALE */}
                    <div className="w-full relative mt-1 font-medium">
                      <div className="w-full flex justify-between text-xs">
                        <div className={`${count === 0 ? 'invisible' : ''}`}>0</div>
                        <div>{bidOption.max}</div>
                      </div>

                      <div
                        className={`text-${bidsBarColor} absolute top-0 text-xs text-right`}
                        style={{ width: `${value}%` }}
                      >
                        {count}
                      </div>
                    </div>
                  </div>
                );
              })}
            </StyledBidStatusWrapper>
          )}

          <StyledWrapper className="mb-1">
            <MyTableGridWrapper>
              <MyGridCore
                allGridData={inputData}
                gridSettings={{
                  gridColumnProps: gridColumnProps,
                  advancedFilter: false,
                  DATA_ITEM_KEY: 'submission_external_id',
                  SELECTED_FIELD: '',
                  LABELABLE_ITEM_KEY: '',
                  fontSize: DEFAULT_FONT_SIZE,
                  dataState: {},
                }}
                dataStateValue={{ ...getInitialDataState(), sort: [{ field: 'affinity', dir: 'desc' }] }}
                selectedState={{}}
                dateFormat={null}
                selectable={false}
                showActionsColumn={false}
              />
            </MyTableGridWrapper>
          </StyledWrapper>
        </div>
      ) : (
        <div className="h-full relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {t('Submission ID')}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t('Title')}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t('Abstract')}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t('Keywords')}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t('Bid')}
                </th>
              </tr>
            </thead>
            <tbody>
              {inputData
                .filter((register) => register.bid_option_id != null)
                .map((register, index) => {
                  return (
                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="px-6 py-4">{register.submission_id}</td>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {register.title}
                      </th>
                      <td className="px-6 py-4">{register.abstract}</td>
                      <td className="px-6 py-4">{register.keywords}</td>
                      <td className="px-6 py-4">{bidOptionsById[register.bid_option_id].name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectBidState(state).loading || selectBidState(state).bidOptions.loading,
  bidsById: selectBidState(state).byId,
  bidOptionsById: selectBidState(state).bidOptions.byId,
  keywordById: selectKeywordState(state).keywordById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOwnBidsAction: () => dispatch(bidSlice.actions['GET:BIDS:OWN']()),
  createBidAction: (data: BidDTO) => dispatch(bidSlice.actions['POST:BID'](data)),
  postConflictAction: (data: ConflictDTO) => dispatch(bidSlice.actions['POST:CONFLICT'](data)),
});

const StyledWrapper = styled.div`
  flex-grow: 1;

  .k-grid {
    min-height: auto !important;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(BiddingPage);

const StyledBidStatusWrapper = styled.div`
  > div {
    margin-right: 0;
  }

  .bidOPtion {
    margin-right: 2rem;
  }

  .bidOPtion:last-child {
    margin-right: 0;
  }
`;
