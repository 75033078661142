import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: SideMenuState = {
  unfolded_items: [],
  selected_item: '',
  menu_open: false,
};

const sidemenuSlice = createSlice({
  name: 'SIDEMENU',
  initialState,
  reducers: {
    reset: () => initialState,
    ['SIDEMENU_OPEN']: (state, action: PayloadAction<string>) => {
      const new_unfolded_items_open = [...state.unfolded_items];
      if (new_unfolded_items_open.includes(action.payload)) {
        return { ...state };
      } else {
        new_unfolded_items_open.push(action.payload);
        return { ...state, unfolded_items: [...new_unfolded_items_open] };
      }
    },
    ['SIDEMENU_CLOSE']: (state, action: PayloadAction<string>) => {
      if (!state.unfolded_items.includes(action.payload)) return state;
      const new_unfolded_items_close = state.unfolded_items.filter((element) => element !== action.payload);
      return { ...state, unfolded_items: [...new_unfolded_items_close] };
    },
    ['SIDEMENU_CLICK']: (state, action: PayloadAction<string>) => ({
      ...state,
      selected_item: action.payload,
    }),
    ['SIDEMENU_SET_MENU_OPEN']: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        menu_open: action.payload,
      };
    },
  },
});

export default sidemenuSlice;
