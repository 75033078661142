import React, { useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { MiniButton } from '../ui/inputs/MiniButton/MiniButton';
import { useTranslation } from 'react-i18next';

interface Props extends GridCellProps {}

const AbstractCell: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState<boolean>(false);
  const field = props.field || '';
  const value = props.dataItem[field] ?? '';

  const characterLimit = 100;

  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      <div className="flex items-center">
        {value.length > characterLimit ? (
          <>
            {expand ? value : value.substring(0, characterLimit) + ' (...)'}
            <div>
              <MiniButton
                labelText={expand ? t('Show less') : t('Show more')}
                onButtonClick={() => {
                  setExpand(!expand);
                }}
              />
            </div>
          </>
        ) : (
          value
        )}
      </div>
    </td>
  );
};

export default AbstractCell;
