import React, { useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '../ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MiniButton } from '../ui/inputs/MiniButton/MiniButton';
import { NotifyDTO } from '../../store/table/types';

interface Props extends GridCellProps {
  idKey: string;
  handleAssignedChange?: (id: number, assigned: boolean) => void;
  notifiableIds: {
    [id: number]: { submission_id: number; person_role_id: number; isDone: boolean };
  };
  handleNotifyClick: (data: NotifyDTO) => void;
}

const AssignedCell: React.FC<Props> = ({ idKey, handleAssignedChange, notifiableIds, handleNotifyClick, ...props }) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const field = props.field || '';
  const value = props.dataItem[field];
  const modelId = props.dataItem[idKey];

  const notifiable = notifiableIds[modelId];

  return (
    <StyledTd
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      {loading ? (
        <p>{t('Working...')}</p>
      ) : (
        <div className="flex items-center">
          <Checkbox
            checked={value}
            onChange={(event) => {
              setLoading(true);
              handleAssignedChange?.(modelId, event.target.checked);
            }}
          />
          {notifiable && (
            <MiniButton
              labelText={notifiable.isDone ? t('Notified') : t('Notify')}
              success={notifiable.isDone}
              onButtonClick={
                notifiable.isDone
                  ? undefined
                  : async () => {
                      setLoading(true);
                      handleNotifyClick({ modelId });
                    }
              }
            />
          )}
        </div>
      )}
    </StyledTd>
  );
};

export default AssignedCell;

const StyledTd = styled.td`
  .notify {
    color: ${(props) => props.theme.palette.primary.main};
    border-color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
  }

  .notified {
    color: #1f9801;
    background-color: #e4f0e1;
  }
`;
