import React, { useEffect } from 'react';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import { Submission } from '../../store/submission/types';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { AppState } from '../../store/state';
import { selectInfo, selectKeywordState, selectPaperStatusState, selectSubmissionState } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import submissionSlice from '../../store/submission/slice';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { PaperStatus } from '../../store/paper-status/types';
import { formatKeywordColumn } from '../../helpers/table';
import { useTranslation } from 'react-i18next';
import { getPaperStatusName } from '../../helpers/translations';
import infoSlice from '../../store/info/slice';

interface Props {
  loading: boolean;
  submissionsById: { [key: string]: Submission };
  getSubmissionsAction: () => void;
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  getInfoAction: (data: InfoGetDTO) => void;
  info: Info;
}

const AllSubmissionsBiddingPage: React.FC<Props> = ({
  loading,
  submissionsById,
  getSubmissionsAction,
  keywordById,
  paperStatusById,
  getInfoAction,
  info,
}) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'submissions_bids';

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction();
    }
    if (Object.keys(info[friendlyName].byId).length === 0) {
      getInfoAction({ friendlyName });
    }
  }, []);

  const chairInfo = info as ChairInfo;

  /* Build input data */
  const inputData: unknown[] = [];
  Object.values(submissionsById).forEach((submission, index) => {
    let newRegister = {
      ...submission,
    };

    formatKeywordColumn(newRegister, keywordById);

    // @ts-ignore
    newRegister.authors_str = newRegister.authors.map((author) => author.first_name + ' ' + author.last_name).join(';');
    newRegister.paper_status = getPaperStatusName(paperStatusById[newRegister.paper_status_id].name, t);

    // @ts-ignore
    newRegister.file_upload_str = newRegister.file_upload?.filename_original;

    // Add bidding info
    const submissionBid = chairInfo.submissions_bids.byId[submission.id] ?? {
      id: submission.id,
      positive: 0,
      negative: 0,
      bids: 0,
    };
    newRegister = { ...newRegister, ...submissionBid };

    inputData.push(newRegister);
  });

  return (
    <>
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Submissions bids')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={() => getInfoAction({ friendlyName })}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectInfo(state).loading,
  submissionsById: selectSubmissionState(state).submissionsById,
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  info: state.info.info,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: () => dispatch(submissionSlice.actions.GET_SUBMISSIONS()),
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllSubmissionsBiddingPage);
