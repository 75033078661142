import { put, takeLatest } from '@redux-saga/core/effects';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import { baseURL } from '../root-saga';
import { Bid, BidDTO, ConflictDTO, GetBidOptionsResponse, GetBidsResponse, PostBidResponse } from './types';
import errorSlice from '../error/slice';
import bidSlice from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { FileUploadUpdaterDTO, UploadFeedbackResponse } from '../submission/types';

const getBidOptions = async (): Promise<Result<GetBidOptionsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<GetBidOptionsResponse>(`${baseURL}/api/bids/options`, init);
};

function* getBidOptionsSaga() {
  const result = (yield getBidOptions()) as Await<ReturnType<typeof getBidOptions>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['GET:BID_OPTIONS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const getOwnBids = async (): Promise<Result<GetBidsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<GetBidsResponse>(`${baseURL}/api/bids/own`, init);
};

function* getOwnBidsSaga() {
  const result = (yield getOwnBids()) as Await<ReturnType<typeof getOwnBids>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['GET:BIDS:OWN:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

export const getOtherBids = async (data: {
  submission_id?: number;
  person_role_id?: number;
}): Promise<Result<{ data: Bid[] }, APIValidationError>> => {
  const { submission_id, person_role_id } = data;
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  let params = '';
  if (submission_id) {
    params = `submission_id=${submission_id}`;
  } else if (person_role_id) {
    params = `person_role_id=${person_role_id}`;
  }

  return apiCall<{ data: Bid[] }>(`${baseURL}/api/bids/other?${params}`, init);
};

const postBid = async (data: BidDTO): Promise<Result<PostBidResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<PostBidResponse>(`${baseURL}/api/bids`, init);
};

function* postBidSaga(data: PayloadAction<BidDTO>) {
  const result = (yield postBid(data.payload)) as Await<ReturnType<typeof postBid>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['POST:BID:OK'](data.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const postConflict = async (data: ConflictDTO): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/bids/conflict`, init);
};

function* postConflictSaga(data: PayloadAction<ConflictDTO>) {
  const result = (yield postConflict(data.payload)) as Await<ReturnType<typeof postConflict>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['POST:CONFLICT:OK'](data.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

export const postSubsetBids = async (
  data: FileUploadUpdaterDTO,
): Promise<Result<UploadFeedbackResponse, APIValidationError>> => {
  const { file, dry_run } = data;

  const formData = new FormData();
  formData.append('file', file);

  const init = {
    method: 'POST',
    auth: true,
    role: true,
    defaultContentType: true,
    body: formData,
  };

  return apiCall<UploadFeedbackResponse>(`${baseURL}/api/bidding/subset?dry_run=${dry_run ? 1 : 0}`, init);
};

export default [
  takeLatest(bidSlice.actions['GET:BID_OPTIONS'], getBidOptionsSaga),
  takeLatest(bidSlice.actions['GET:BIDS:OWN'], getOwnBidsSaga),
  takeLatest(bidSlice.actions['POST:BID'], postBidSaga),
  takeLatest(bidSlice.actions['POST:CONFLICT'], postConflictSaga),
];
