import React from 'react';
import styled from 'styled-components';

export type MiniButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  labelText: string;
  success?: boolean;
  onButtonClick?: () => void;
};

export const MiniButton = ({ labelText, success, onButtonClick }: MiniButtonProps): JSX.Element => {
  return (
    <StyledButton className={`ml-3 px-1.5 rounded border text-xs ${success ? 'success' : ''}`} onClick={onButtonClick}>
      {labelText}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  color: ${(props) => props.theme.palette.primary.main};
  border-color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  white-space: nowrap;

  &.success {
    color: #1f9801;
    border-color: #e4f0e1;
    background-color: #e4f0e1;
    cursor: default;
  }
`;
