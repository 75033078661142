import React from 'react';
import SignInPage from '../pages/SignInPage';
import LayoutCtrl from './LayoutCtrl';
import PeoplePage from '../pages/chair/PeoplePage';
import ConferencesPage from '../pages/ConferencesPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import SignUpPage from '../pages/SignUpPage';
import SendEmailVerificationPage from '../pages/SendEmailVerificationPage';
import EmailVerificationPage from '../pages/EmailVerificationPage';
import AuthCallbackPage from '../pages/AuthCallbackPage';
import SettingsUserPage from '../pages/SettingsUserPage';
import HomePage from '../pages/HomePage';
import SignUpOAuthPage from '../pages/SignUpOAuthPage';
import InvitationFormPage from '../pages/InvitationFormPage';
import checkUrl from '../helpers/checkUrls';
import ConferencesCreatePage from '../pages/ConferencesCreatePage';
import AuthLayout from '../layouts/AuthLayout';
import SettingsFormsPage from '../pages/chair/SettingsFormsPage';
import ThemePlayPage from '../pages/ThemePlayPage';
import AllSubmissionsPage from '../pages/chair/AllSubmissionsPage';
import EmailHistoryPage from '../pages/chair/EmailHistoryPage';
import BiddingPage from '../pages/BiddingPage';
import AllReviewersBiddingPage from '../pages/chair/AllReviewersBiddingPage';
import DiscussionPage from '../pages/DiscussionPage';
import SubmissionDetailPage from '../pages/SubmissionDetailPage';
import SettingsRolesPage from '../pages/chair/SettingsRolesPage';
import SubmissionEditPage from '../pages/SubmissionEditPage';
import SubmissionNewPage from '../pages/SubmissionNewPage';
import RecruitmentPage from '../pages/chair/RecruitmentPage';
import ReviewEditPage from '../pages/ReviewEditPage';
import BulkImportPage from '../pages/chair/BulkImportPage';
import ReviewersToPapersPage from '../pages/chair/ReviewersToPapersPage';
import PapersToReviewersPage from '../pages/chair/PapersToReviewersPage';
import SettingsAnnouncementsPage from '../pages/chair/SettingsAnnouncementsPage';
import SettingsKeywordsPage from '../pages/chair/SettingsKeywordsPage';
import DashboardPage from '../pages/chair/DashboardPage';
import KeywordsPage from '../pages/KeywordsPage';
import SettingsConferencePage from '../pages/chair/SettingsConferencePage';
import SettingsPaperStatusPage from '../pages/chair/SettingsPaperStatusPage';
import SettingsPlanningPage from '../pages/chair/SettingsPlanningPage';
import ExitConferencePage from '../pages/ExitConferencePage';
import LogoutPage from '../pages/LogoutPage';
import AllSubmissionsBiddingPage from '../pages/chair/AllSubmissionsBiddingPage';
import { PermissionString } from '../store/permission/types';
import EmailTemplatesPage from '../pages/chair/EmailTemplatesPage';
import ReviewerBidsPage from '../pages/ReviewerBidsPage';
import { MenuItemName } from '../layouts/types';
import SubmissionBidsPage from '../pages/SubmissionBidsPage';
import ReviewerAssignmentsPage from '../pages/ReviewerAssignmentsPage';
import SubmissionAssignmentsPage from '../pages/SubmissionAssignmentsPage';

export interface RouteDefinition {
  name: RouteName;
  path: string;
  logged_access: boolean;
  anonymous_access: boolean;
  email_verified_access: boolean;
  impersonated_access: boolean;
  track_selected_access: boolean;
  permission?: PermissionString;
  fragment: React.ReactNode;
  main_menu_option?: MenuItemName;
}

export const getRouteByName = (name: RouteName): RouteDefinition => {
  const route = appRoutes.find((element) => element.name === name);
  if (!route) throw new Error('Route not found. This must not happen');
  return route;
};

export const getRouteByPath = (path: string): RouteDefinition => {
  const route = appRoutes.find((r: RouteDefinition) => checkUrl(r.path, path));
  if (!route) throw new Error('Route not found. This must not happen');
  return route;
};

export type RouteName =
  | 'RouteRoot'
  | 'RouteExitConference'
  | 'RouteConferences'
  | 'RouteConferenceHome'
  | 'RouteConferencesCreate'
  | 'RoutePeople'
  | 'RouteSettingsUser'
  | 'RouteSettingsForms'
  | 'RouteSettingsRelationsRoles'
  | 'RouteSettingsRoles'
  | 'RouteSettingsKeywords'
  | 'RouteSettingsPlanning'
  | 'RouteAllSubmissions'
  | 'RouteDetailSubmission'
  | 'RouteEditSubmission'
  | 'RouteNewSubmission'
  | 'RouteLogin'
  | 'RouteLogout'
  | 'RouteSignUp'
  | 'RouteSignUpOAuth'
  | 'RouteForgotPassword'
  | 'RouteResetPasswordPage'
  | 'RouteSendEmailVerificationPage'
  | 'RouteEmailVerifyPage'
  | 'RouteAuthCallbackPage'
  | 'RouteInvitationFormPage'
  | 'RouteBidding'
  | 'RouteAllBidding'
  | 'RouteAllReviewersBids'
  | 'RouteAllSubmissionsBids'
  | 'RouteEmailTemplatesPage'
  | 'RouteEmailHistoryPage'
  | 'RouteRecruitmentPage'
  | 'RouteAssignments'
  | 'RouteDiscussions'
  | 'RouteThemePlayPage'
  | 'RouteEditReview'
  | 'RouteAssigmentBulkImport'
  | 'RouteAssigmentReviewerToPaper'
  | 'RouteAssigmentPaperToReviewer'
  | 'RouteSettingsAnnouncements'
  | 'RouteDashboard'
  | 'RouteKeywords'
  | 'RouteSettingsConference'
  | 'RouteSettingsPaperStatus'
  | 'RouteSettingsPhases'
  | 'RouteReviewerBids'
  | 'RouteReviewerAssignments'
  | 'RouteSubmissionBids'
  | 'RouteSubmissionAssignments';

const baseTrackPath = '/conferences/:conference/:track';

export const appRoutes: RouteDefinition[] = [
  {
    name: 'RouteRoot',
    path: '/',
    logged_access: true,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: false,
    track_selected_access: false,
    fragment: <div></div>,
  },
  {
    name: 'RouteConferences',
    path: '/conferences',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: false,
    track_selected_access: false,
    fragment: (
      <LayoutCtrl>
        <ConferencesPage />
      </LayoutCtrl>
    ),
  },
  {
    name: 'RouteConferencesCreate',
    path: '/conferences/create',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: false,
    track_selected_access: false,
    fragment: (
      <LayoutCtrl>
        <ConferencesCreatePage />
      </LayoutCtrl>
    ),
  },
  {
    name: 'RouteExitConference',
    path: '/exit',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: false,
    track_selected_access: true,
    fragment: <ExitConferencePage />,
  },
  {
    name: 'RouteConferenceHome',
    path: baseTrackPath,
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    fragment: (
      <LayoutCtrl>
        <HomePage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Submissions',
  },
  {
    name: 'RoutePeople',
    path: baseTrackPath + '/people',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_PEOPLE',
    fragment: (
      <LayoutCtrl>
        <PeoplePage />
      </LayoutCtrl>
    ),
    main_menu_option: 'People',
  },
  {
    name: 'RouteSettingsUser',
    path: '/settings',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: false,
    track_selected_access: false,
    fragment: (
      <LayoutCtrl>
        <SettingsUserPage />
      </LayoutCtrl>
    ),
  },
  {
    name: 'RouteSettingsForms',
    path: baseTrackPath + '/settings/forms',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_TRACK_SETTINGS',
    fragment: (
      <LayoutCtrl>
        <SettingsFormsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Forms',
  },
  {
    name: 'RouteSettingsRoles',
    path: baseTrackPath + '/settings/roles',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_TRACK_SETTINGS',
    fragment: (
      <LayoutCtrl>
        <SettingsRolesPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Roles',
  },
  {
    name: 'RouteSettingsPlanning',
    path: baseTrackPath + '/settings/planning',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_TRACK_SETTINGS',
    fragment: (
      <LayoutCtrl>
        <SettingsPlanningPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Planning',
  },
  {
    name: 'RouteAllSubmissions',
    path: baseTrackPath + '/submissions',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_SUBMISSIONS',
    fragment: (
      <LayoutCtrl>
        <AllSubmissionsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Submissions',
  },
  {
    name: 'RouteNewSubmission',
    path: baseTrackPath + '/submissions/create',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'CAN_NEW_SUBMISSION',
    fragment: (
      <LayoutCtrl>
        <SubmissionNewPage />
      </LayoutCtrl>
    ),
  },
  {
    name: 'RouteDetailSubmission',
    path: baseTrackPath + '/submissions/:id',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    fragment: (
      <LayoutCtrl>
        <SubmissionDetailPage />
      </LayoutCtrl>
    ),
  },
  {
    name: 'RouteEditSubmission',
    path: baseTrackPath + '/submissions/:id/edit',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    fragment: (
      <LayoutCtrl>
        <SubmissionEditPage />
      </LayoutCtrl>
    ),
  },
  {
    name: 'RouteRecruitmentPage',
    path: baseTrackPath + '/communications' + '/recruitment',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_COMMUNICATIONS',
    fragment: (
      <LayoutCtrl>
        <RecruitmentPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Recruitment',
  },
  {
    name: 'RouteEmailTemplatesPage',
    path: baseTrackPath + '/communications' + '/templates',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_COMMUNICATIONS',
    fragment: (
      <LayoutCtrl>
        <EmailTemplatesPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Email templates',
  },
  {
    name: 'RouteEmailHistoryPage',
    path: baseTrackPath + '/communications' + '/history',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_COMMUNICATIONS',
    fragment: (
      <LayoutCtrl>
        <EmailHistoryPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Email history',
  },
  {
    name: 'RouteAllReviewersBids',
    path: baseTrackPath + '/bidding/reviewers',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_BIDDING',
    fragment: (
      <LayoutCtrl>
        <AllReviewersBiddingPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Reviewers bids',
  },
  {
    name: 'RouteReviewerBids',
    path: baseTrackPath + '/bidding/reviewers/:id',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_BIDDING',
    fragment: (
      <LayoutCtrl>
        <ReviewerBidsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Reviewers bids',
  },
  {
    name: 'RouteBidding',
    path: baseTrackPath + '/bidding',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_BIDDING',
    fragment: (
      <LayoutCtrl>
        <BiddingPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Bidding',
  },
  {
    name: 'RouteAllSubmissionsBids',
    path: baseTrackPath + '/bidding/submissions',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_BIDDING',
    fragment: (
      <LayoutCtrl>
        <AllSubmissionsBiddingPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Submissions bids',
  },
  {
    name: 'RouteSubmissionBids',
    path: baseTrackPath + '/bidding/submissions/:id',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_BIDDING',
    fragment: (
      <LayoutCtrl>
        <SubmissionBidsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Submissions bids',
  },
  {
    name: 'RouteDiscussions',
    path: baseTrackPath + '/submissions/:submissionId/discussion',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    fragment: (
      <LayoutCtrl>
        <DiscussionPage />
      </LayoutCtrl>
    ),
  },
  {
    name: 'RouteLogin',
    path: '/login',
    logged_access: false,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: (
      <AuthLayout>
        <SignInPage />
      </AuthLayout>
    ),
  },
  {
    name: 'RouteLogout',
    path: '/logout',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: <LogoutPage />,
  },
  {
    name: 'RouteSignUp',
    path: '/signup',
    logged_access: false,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: (
      <AuthLayout>
        <SignUpPage />
      </AuthLayout>
    ),
  },
  {
    name: 'RouteSignUpOAuth',
    path: '/signup-oauth',
    logged_access: false,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: (
      <AuthLayout>
        <SignUpOAuthPage />
      </AuthLayout>
    ),
  },
  {
    name: 'RouteForgotPassword',
    path: '/forgot-password',
    logged_access: false,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: (
      <AuthLayout>
        <ForgotPasswordPage />
      </AuthLayout>
    ),
  },
  {
    name: 'RouteResetPasswordPage',
    path: '/reset-password/:token',
    logged_access: false,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: (
      <AuthLayout>
        <ResetPasswordPage />
      </AuthLayout>
    ),
  },
  {
    name: 'RouteSendEmailVerificationPage',
    path: '/verification-notification',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: (
      <AuthLayout>
        <SendEmailVerificationPage />
      </AuthLayout>
    ),
  },
  {
    name: 'RouteEmailVerifyPage',
    path: '/email/verify/:id/:token',
    logged_access: true,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: <EmailVerificationPage />,
  },
  {
    name: 'RouteAuthCallbackPage',
    path: '/oauth/callback/:provider',
    logged_access: false,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: <AuthCallbackPage />,
  },
  {
    name: 'RouteInvitationFormPage',
    path: '/invitation/:token',
    logged_access: true,
    anonymous_access: true,
    email_verified_access: false,
    impersonated_access: true,
    track_selected_access: false,
    fragment: (
      <AuthLayout>
        <InvitationFormPage />
      </AuthLayout>
    ),
  },
  {
    name: 'RouteThemePlayPage',
    path: '/themeplay',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: false,
    fragment: <ThemePlayPage />,
  },
  {
    name: 'RouteEditReview',
    path: baseTrackPath + '/submissions/:submissionId/reviews/:reviewId/edit',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    fragment: (
      <LayoutCtrl>
        <ReviewEditPage />
      </LayoutCtrl>
    ),
  },
  {
    name: 'RouteAssigmentBulkImport',
    path: baseTrackPath + '/assignments' + '/bulk-import',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_REVIEWS',
    fragment: (
      <LayoutCtrl>
        <BulkImportPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Bulk import',
  },
  {
    name: 'RouteAssigmentReviewerToPaper',
    path: baseTrackPath + '/assignments/reviewers-to-papers',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_REVIEWS',
    fragment: (
      <LayoutCtrl>
        <ReviewersToPapersPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Reviewers to papers',
  },
  {
    name: 'RouteReviewerAssignments',
    path: baseTrackPath + '/assignments/reviewers-to-papers/:id',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_REVIEWS',
    fragment: (
      <LayoutCtrl>
        <ReviewerAssignmentsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Reviewers to papers',
  },
  {
    name: 'RouteAssigmentPaperToReviewer',
    path: baseTrackPath + '/assignments/papers-to-reviewers',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_REVIEWS',
    fragment: (
      <LayoutCtrl>
        <PapersToReviewersPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Papers to reviewers',
  },
  {
    name: 'RouteSubmissionAssignments',
    path: baseTrackPath + '/assignments/papers-to-reviewers/:submissionId/:roleId',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_ALL_REVIEWS',
    fragment: (
      <LayoutCtrl>
        <SubmissionAssignmentsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Papers to reviewers',
  },
  {
    name: 'RouteSettingsAnnouncements',
    path: baseTrackPath + '/settings/announcements',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_TRACK_SETTINGS',
    fragment: (
      <LayoutCtrl>
        <SettingsAnnouncementsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Announcements',
  },
  {
    name: 'RouteDashboard',
    path: baseTrackPath + '/dashboard/:tab',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_DASHBOARD',
    fragment: (
      <LayoutCtrl>
        <DashboardPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Dashboard',
  },
  {
    name: 'RouteKeywords',
    path: baseTrackPath + '/keywords',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    fragment: (
      <LayoutCtrl>
        <KeywordsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'User keywords',
  },
  {
    name: 'RouteSettingsConference',
    path: baseTrackPath + '/settings/conference-settings',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_TRACK_SETTINGS',
    fragment: (
      <LayoutCtrl>
        <SettingsConferencePage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Conference',
  },
  // NEW SETTINGS MENU
  {
    name: 'RouteSettingsKeywords',
    path: baseTrackPath + '/settings/keywords',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_TRACK_SETTINGS',
    fragment: (
      <LayoutCtrl>
        <SettingsKeywordsPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Keywords',
  },
  {
    name: 'RouteSettingsPaperStatus',
    path: baseTrackPath + '/settings/paper-status',
    logged_access: true,
    anonymous_access: false,
    email_verified_access: true,
    impersonated_access: true,
    track_selected_access: true,
    permission: 'ACCESS_TRACK_SETTINGS',
    fragment: (
      <LayoutCtrl>
        <SettingsPaperStatusPage />
      </LayoutCtrl>
    ),
    main_menu_option: 'Paper status',
  },
];
