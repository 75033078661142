import React from 'react';
import styled from 'styled-components';
import { Button, Link } from '../../components/ui';
import history from '../../store/history';
import { fillRoutePath } from '../../helpers/path';
import { getRouteByName } from '../../router/routes';
import ArrowDownCircle from '../../icons/ArrowDownCircle';
import ArrowUpCircle from '../../icons/ArrowUpCircle';

type HeaderContent = {
  title: string;
  row1: {
    title: string;
    content: string | string[];
  };
  row2: {
    title: string;
    content: string | string[];
  };
  row3: {
    title: string;
    content: string | string[];
  };
  row4: {
    title: string;
    content: string | string[];
  };
};

interface Props {
  onPageTitleClick: () => void;
  originPageTitle: string;
  headerContent: HeaderContent;
}

const HeaderEditBiddingAssignment: React.FC<Props> = ({ onPageTitleClick, originPageTitle, headerContent }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  return (
    <>
      <h2 className="text-xl font-bold text-gray-700 flex items-center">
        <Link className="flex items-center underline mr-2.5 cursor-pointer" onClick={onPageTitleClick}>
          {originPageTitle}
        </Link>
        {`>`} <span className="ml-2.5">{headerContent.title}</span>
        <StyledButton
          className="expand-button"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
          variant="text"
          icon={isCollapsed ? <ArrowDownCircle /> : <ArrowUpCircle />}
        ></StyledButton>
      </h2>

      {!isCollapsed && (
        <div className="flex flex-row mt-3 text-sm">
          <StyledHeaderTextWrapper className="w-2/4 pr-4">
            <div>
              <span className="font-bold">{headerContent.row1.title}:</span> {headerContent.row1.content}
            </div>

            <div>
              <span className="font-bold">{headerContent.row2.title}:</span> {headerContent.row2.content}
            </div>

            <div>
              <span className="font-bold">{headerContent.row3.title}:</span> {headerContent.row3.content}
            </div>
          </StyledHeaderTextWrapper>

          <StyledHeaderTextWrapper className="w-2/4 pr-4">
            <div>
              <span className="font-bold">{headerContent.row4.title}:</span> {headerContent.row4.content}
            </div>
          </StyledHeaderTextWrapper>
        </div>
      )}
    </>
  );
};

export default HeaderEditBiddingAssignment;

const StyledHeaderTextWrapper = styled.div`
  > div {
    line-height: 1.55;
  }
`;

const StyledButton = styled(Button)`
  padding-top: 0;
  padding-bottom: 0;
  height: fit-content;
`;
