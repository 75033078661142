import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';
import * as _ from 'lodash';
import { MiniButton } from '../ui/inputs/MiniButton/MiniButton';

interface Props extends GridCellProps {
  handleOnClick?: () => void;
  labelText: string;
}

const IdCell: React.FC<Props> = ({ handleOnClick, labelText, ...props }) => {
  const field = props.field || '';
  // Here we get the value through lodash get method: https://lodash.com/docs/4.17.15#get
  // This is because field contains a dot notation string key: 'reviews_summary.14.total_reviews'
  const value = _.get(props.dataItem, field);

  return (
    <StyledTdId
      colSpan={props.colSpan}
      className={`${props.className} cursor-pointer`}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
      onClick={handleOnClick}
    >
      <a>
        <div className="flex items-center">
          {value}

          <div className="hidden-button invisible">
            <MiniButton labelText={labelText} />
          </div>
        </div>
      </a>
    </StyledTdId>
  );
};

export default IdCell;

const StyledTdId = styled.td`
  :hover {
    .hidden-button {
      visibility: visible;
    }
  }
`;
