import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { Submission } from '../store/submission/types';
import submissionSlice from '../store/submission/slice';
import {
  selectBidState,
  selectInfo,
  selectKeywordState,
  selectPaperStatusState,
  selectSubmissionState,
  selectTable,
  selectTrackRoles,
} from '../store/selectors';
import { connect } from 'react-redux';
import Loading from '../components/Loading/Loading';
import { ChairInfo, DeleteInfoDTO, Info, InfoGetDTO, TableFriendlyName } from '../store/info/types';
import { formatKeywordColumn } from '../helpers/table';
import { getMenuItemName, getPaperStatusName, getValidationStatusName } from '../helpers/translations';
import { PaperStatus } from '../store/paper-status/types';
import MyGridRedux from '../components/MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';
import HeaderEditBiddingAssignment from '../components/HeaderEditBiddingAssignment/HeaderEditBiddingAssignment';
import { Role } from '../store/conference/types';
import infoSlice from '../store/info/slice';
import { getRouteByName } from '../router/routes';
import history from '../store/history';
import { fillRoutePath } from '../helpers/path';
import { MenuItemName } from '../layouts/types';
import { BidOption, ReviewerTableRow } from '../store/bid/types';
import { getReviewer } from '../helpers/users';
import { NotifiablesUpdateDTO } from '../store/table/types';
import tableSlice from '../store/table/slice';
import { useAssignmentDetail } from '../store/review/hooks';

interface Props {
  getSubmissionsAction: () => void;
  submissionsById: { [key: string]: Submission };
  loading: boolean;
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  info: Info;
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  bidOptionsById: { [key: number]: BidOption };
  customColumnsData: { [key: string]: { [key: number]: { [key: string]: any } } };
  updateNotifiablesAction: (data: NotifiablesUpdateDTO) => void;
  clearNotifiablesAction: () => void;
  deleteInfoAction: (data: DeleteInfoDTO) => void;
}

const ReviewerAssignmentsPage = ({
  getSubmissionsAction,
  submissionsById,
  loading,
  keywordById,
  paperStatusById,
  info,
  roleById,
  getInfoAction,
  bidOptionsById,
  customColumnsData,
  updateNotifiablesAction,
  clearNotifiablesAction,
  deleteInfoAction,
}: Props) => {
  const { t, i18n } = useTranslation();
  const params: any = useParams();
  const id = parseInt(params.id);

  const chairInfo = info as ChairInfo;
  const reviewer: ReviewerTableRow | undefined = getReviewer(id, chairInfo.users);
  const [additionalInfo, additionalInfoLoading, fetchData, updateAssignmentAction] = useAssignmentDetail(
    'person_role_id',
    id,
    updateNotifiablesAction,
    deleteInfoAction,
  );

  const friendlyName: TableFriendlyName = 'reviewer_assignments';

  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    }
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction();
    }
    if (!customColumnsData[friendlyName]) {
      getInfoAction({ friendlyName });
    }
    return clearNotifiablesAction; // Clear notifications at page exit
  }, []);

  /* Fetch reviewer bids */
  useEffect(() => {
    if (reviewer) {
      fetchData();
    }
  }, [reviewer != undefined]);

  if (loading || additionalInfoLoading) {
    return <Loading />;
  } else if (!reviewer) {
    return <div>Reviewer #{id} not found</div>;
  }

  /* Generate data for table */
  const inputData: unknown[] = [];
  additionalInfo.forEach((additional) => {
    const newRegister = {
      ...submissionsById[additional.submission_id],
      affinity: additional.affinity,
      bid: additional.bid_option_id ? bidOptionsById[additional.bid_option_id].name : undefined,
      total_reviews: additional.total_reviews,
      assigned: additional.assigned,
      completed: additional.completed,
    };

    formatKeywordColumn(newRegister, keywordById);

    // @ts-ignore
    newRegister.authors_str = newRegister.authors.map((author) => author.first_name + ' ' + author.last_name).join(';');
    newRegister.paper_status = getPaperStatusName(paperStatusById[newRegister.paper_status_id].name, t);
    // @ts-ignore
    newRegister.file_upload_str = newRegister.file_upload?.filename_original;

    // @ts-ignore
    newRegister.validation_status = getValidationStatusName(newRegister.validation_status, t);

    inputData.push(newRegister);
  });

  /* Generate header */
  const headerContent = {
    title: reviewer.full_name ?? '',
    row1: {
      title: t('Role'),
      content: roleById[reviewer.role_id].description,
    },
    row2: {
      title: t('Email'),
      content: reviewer?.email ?? '',
    },
    row3: {
      title: t('Main area'),
      content:
        reviewer && reviewer?.keyword_ids.length > 0
          ? (reviewer?.keyword_ids
              .map((id) => {
                const parentId = keywordById[id].parent_id;
                return parentId && keywordById[parentId].name + '. ';
              })
              .filter((item, index, self) => self.indexOf(item) === index) as string[]) //to avoid duplicate main areas
          : t('The reviewer has not selected keywords.'),
    },
    row4: {
      title: t('Keywords'),
      content:
        reviewer && reviewer.keyword_ids.length > 0
          ? (reviewer?.keyword_ids.map((id) => keywordById[id].name + '. ') as string[])
          : t('The reviewer has not selected keywords.'),
    },
  };

  const parentRoute = getRouteByName('RouteAssigmentReviewerToPaper');

  return (
    <div className="flex flex-col h-full">
      <HeaderEditBiddingAssignment
        onPageTitleClick={() => history.push(fillRoutePath(parentRoute.path))}
        originPageTitle={getMenuItemName(parentRoute.main_menu_option as MenuItemName, t)}
        headerContent={headerContent}
      />
      <div className="mt-8" />
      <MyGridRedux
        friendlyName={friendlyName}
        inputData={inputData as Record<string, unknown>[]}
        updateAssignmentAction={updateAssignmentAction}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  submissionsById: selectSubmissionState(state).submissionsById,
  loading: selectSubmissionState(state).loading || selectInfo(state).loading,
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  info: state.info.info,
  roleById: selectTrackRoles(state),
  bidOptionsById: selectBidState(state).bidOptions.byId,
  customColumnsData: selectTable(state).customColumnsData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: () => dispatch(submissionSlice.actions.GET_SUBMISSIONS()),
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  updateNotifiablesAction: (data: NotifiablesUpdateDTO) => dispatch(tableSlice.actions['NOTIFIABLES:UPDATE'](data)),
  clearNotifiablesAction: () => dispatch(tableSlice.actions['NOTIFIABLES:CLEAR']()),
  deleteInfoAction: (data: DeleteInfoDTO) => dispatch(infoSlice.actions.DELETE(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerAssignmentsPage);
